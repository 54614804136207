import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Stack,
  IconButton,
  Drawer,
  Box,
  List,
  ListItem,
  Divider,
  ListItemButton,
  ListItemIcon,
  InboxIcon,
  ListItemText,
  DraftsIcon,
  Card,
  CardActions,
  CardContent,
} from "@mui/material";

function createData(hn_ID, user_ID, status_work, staff, time) {
  return {
    hn_ID,
    user_ID,
    status_work,
    staff,
    time,
  };
}

const input_notification = [
  createData(
    "5561432",
    "นายสารินทร์ มากสุข",
    " เวรเปลรับงาน",
    "นายปกรณ์ หวังวิริยุะกุล",
    "18:25:30 น."
  ),
  createData(
    "555525",
    " นายดนัย ไผ่ทอง",
    "ส่งต่องาน",
    "นายศรัณย์ ปานสุข",
    "11:00:30 น."
  ),
  createData(
    "555525",
    "นายดนัย ไผ่ทอง",
    "ดําเนินการสําเร็จ",
    "นายปกรณ์ หวังวิริยุะกุล",
    "11:52:30 น."
  ),
];

export default function ListNotification() {
  return (
    <Stack spacing={0} direction="column" sx={{}}>
      {input_notification.map((data, index) => {
        return (
          // disablePadding
          <List disablePadding key={data.hn_ID} sx={{}}>
            <ListItemButton
              sx={{
                paddingBottom: "3%",
                fontWeight: "600",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "start",
              }}
            >
              <div style={{ width: "100%", textAlign: "end" }}>
                <Typography
                  variant="h6"
                  sx={{ Width: "100%", fontSize: "0.79rem" }}
                >
                  {data.time}
                </Typography>
              </div>
              <Stack spacing={1}>
                <Stack spacing={1} direction="row" sx={{ color: "#5872e9" }}>
                  <Typography fontSize="0.9rem" variant="h6">
                    {" "}
                    <text style={{ color: "black" }}>HN : </text>
                    {data.hn_ID}
                  </Typography>
                  <Typography fontSize="0.9rem" variant="h6">
                    {data.user_ID}
                  </Typography>
                </Stack>
                <Stack spacing={1} direction="row">
                  <Typography fontSize="0.9rem" variant="h6" color="#45c817">
                    <text style={{ color: "black" }}>สถานะดำเนินการ : </text>
                    {data.status_work}
                  </Typography>
                  <Typography fontSize="0.9rem" variant="h6" color="#5872e9">
                    ({data.staff})
                  </Typography>
                </Stack>
              </Stack>
            </ListItemButton>
            <Divider />
          </List>
        );
      })}
    </Stack>
  );
}
