import React, { useState, useEffect, useRef } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

import { useParams } from 'react-router-dom';
import ReactTable from "react-table-6";
import "react-table-6/react-table.css"
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from '@mui/icons-material/Cancel';
import { TablePagination } from '@mui/material';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { th } from 'date-fns/locale';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { makeStyles } from '@mui/styles';
import { SnackbarProvider, useSnackbar } from 'notistack';

import { format, addDays } from 'date-fns';

import {
    Badge,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    TextField,
    Box,
    Divider,
    Checkbox,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    IconButton,
    Card, CardContent,
    FormControlLabel,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,

} from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import styled from "styled-components";
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';

import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import CopyIcon from '@mui/icons-material/FileCopy';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { useLocation } from 'react-router-dom';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top:  -1em;
  margin-bottom: 2em;
`;

const useStyles = makeStyles((theme) => ({
    badge: {
        fontSize: 14
    }
}));

const useStyles_icon = makeStyles((theme) => ({
    squareButton: {
        borderRadius: 10,
        padding: 0,
        width: '180px',
        height: '50px',
        backgroundColor: (props) => props.notifications > 0 ? '#FED523' : 'white',  // check the condition

    },
    badge: {
        right: -3,
        top: 13,
        padding: '0 4px',
    },
}));



const Booking = () => {
    const [notifications, setNotifications] = useState(0);


    document.title = 'Booking - ระบบการจองคิวล่วงหน้า (Advance booking system)';
    const classes_icon = useStyles_icon({ notifications });

    const navigate = useNavigate();

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [rows, setRows] = useState([]);
    const [zones, setZones] = useState([]);
    const [footsets, setFootset] = useState([]);


    const [properties, setProperties] = useState({
        backgroundColor: "",
        color: "",
    });

    const [customers, setCustomers] = useState([
        {
            customer_id: 'CUST000001',
            customer_name: 'กรุ๊ปทัวร์',
            customer_tel: '091234657',
        },
        {
            customer_id: 'CUST000002',
            customer_name: 'คุณลิง4',
            customer_tel: '091122612',
        }
    ]);




    const [items, setItems] = useState([]);
    const [itemShow, setItemShow] = useState([]);

    const [urlslipValues, setUrlslipValues] = useState('-');
    const [refcode, setRefcode] = useState('-');


    const readdatashow = () => {

        console.log(Object.keys(localStorage).filter(key => localStorage.getItem(key) === 'clicked'));
        console.log(Object.keys(localStorage).filter(key => localStorage.getItem(key) === 'show'));

        const data = Object.keys(localStorage)
            .filter(key => localStorage.getItem(key) === 'show')
            .map(key => key.split('|'));

        const dataid = Object.keys(localStorage)
            .filter(key => localStorage.getItem(key) === 'clicked')
            .map(key => key.split('|'));

        const groupedData = data.reduce((acc, curr, index) => {
            console.log(`${curr[0]}|${curr[1]}|${curr[2]}`)
            const key = `${curr[0]}|${curr[1]}|${curr[2]}`;
            if (!acc[key]) {
                acc[key] = {
                    date: curr[0],
                    zone: curr[1],
                    time: curr[2],
                    zoneid: dataid[index][1],
                    timeid: dataid[index][2],
                    quantity: 1
                };
            } else {
                acc[key].quantity += 1;
            }
            return acc;
        }, {});

        const groupedDatasend = data.reduce((acc, curr, index) => {
            console.log(`${curr[0]}|${curr[1]}|${curr[2]}|${curr[3]}`)
            const key = `${curr[0]}|${curr[1]}|${curr[2]}|${curr[3]}`;
            if (!acc[key]) {
                acc[key] = {
                    date: curr[0],
                    zone: curr[1],
                    time: curr[2],
                    zoneid: dataid[index][1],
                    timeid: dataid[index][2],
                    quantity: 1
                };
            } else {
                acc[key].quantity += 1;
            }
            return acc;
        }, {});

        setItemShow(Object.values(groupedDatasend))
        setItems(Object.values(groupedData));

        //console.log(items)

    }



    let { date } = useParams();


    const currentDate = new Date(date);
    const nowDate = new Date();
    const nextDate = addDays(currentDate, 1);
    const backDate = addDays(currentDate, -1);

    const nextDatedata = format(nextDate, 'yyyy-MM-dd');
    const backDatedata = format(backDate, 'yyyy-MM-dd');



    const handleNextDate = () => {
        navigate(`/booking/${nextDatedata}`);
        loaddata(nextDatedata, values.zone)
        setValues(prevValues => ({ ...prevValues, date: nextDatedata }));


    };
    const handleBackDate = () => {
        navigate(`/booking/${backDatedata}`);
        loaddata(backDatedata, values.zone)

        setValues(prevValues => ({ ...prevValues, date: backDatedata }));

    };
    const [loading, setLoading] = useState(true);
    const textRef = useRef();


    const [dialogOpen, setDialogOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState(null);



    const [selectedCustomer, setSelectedCustomer] = useState('');
    const handleCustomerSelect = (event, value) => {
        setSelectedCustomer(value ? value : '');


    };

    const handleCopy = () => {
        if (textRef.current) {
            const text = textRef.current.value;
            if (navigator.clipboard) {
                navigator.clipboard.writeText(text);
            } else {
                // Fallback for browsers that do not support clipboard API
                var textArea = document.createElement("textarea");
                textArea.value = text;
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();
                try {
                    document.execCommand('copy');
                } catch (err) {
                    console.error('Fallback: Oops, unable to copy', err);
                }
                document.body.removeChild(textArea);
            }
            Swal.fire('Copy URL', 'copy url success', 'success');

        }
    };


    const [values, setValues] = useState({
        date: date,
        dateend: null,
        count_cus: 0,
        deposit: 0,
        paid: false,
        zone: "%",
    });

    const handleChangeData = (prop) => (event) => {
        if (prop === 'paid') {
            setValues({ ...values, [prop]: event.target.checked });

        } else if (prop === 'count_cus') {
            let value = event.target.value;
 
            setValues({ ...values, 'count_cus': value });
        } else if (prop === 'date') {
            let value = event;
            setValues({ ...values, [prop]: value });
        } else if (prop === 'dateend') {
            let value = event;
            setValues({ ...values, [prop]: value });
        }
        else {
            let value = event.target.value;
            setValues({ ...values, [prop]: value });
        }
    };

    const formatDate = (date) => {
        const newDate = new Date(date);
        const year = newDate.getFullYear();
        const month = String(newDate.getMonth() + 1).padStart(2, '0');
        const day = String(newDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }


    const imagePath = process.env.PUBLIC_URL + '/imgUpload/';
    const [clickedButtons, setClickedButtons] = useState(() => {
        let initialState = {};
        rows.forEach((row, rowIndex) => {
            row.zone.forEach((zone, zoneIndex) => {
                const total = zone.total;
                for (let index = 0; index < total; index++) {
                    const buttonKey = `${date}|${zone.zoneid}|${row.aroundid}|${index}`;
                    initialState[buttonKey] = !!localStorage.getItem(buttonKey);
                }
            });
        });
        return initialState;
    });

    const handleClickVariant = (data, variant) => () => {
        // variant could be success, error, warning, info, or default
        enqueueSnackbar(data, {
            variant, autoHideDuration: 1000, anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            }
        });
    };

    const loaddata = (datedata, zone) => {
        const token = localStorage.getItem('SavedToken');

        axios.get(process.env.REACT_APP_BASE_API_URL + `/api/zone/`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                setZones(res.data);
                console.log(res.data)

                setLoading(false);

                //Swal.close();


            });


        setRows([])
        Swal.fire({
            title: 'Loading...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });
        Object.keys(localStorage).forEach(key => {
            if (localStorage.getItem(key) === 'clicked') {
                localStorage.removeItem(key);
                setClickedButtons(prevState => ({
                    ...prevState,
                    [key]: false
                }));
            }
        });

        Object.keys(localStorage).forEach(key => {
            if (localStorage.getItem(key) === 'show') {
                localStorage.removeItem(key);
                setClickedButtons(prevState => ({
                    ...prevState,
                    [key]: false
                }));
            }
        });


        axios.get(process.env.REACT_APP_BASE_API_URL + `/api/booking/${datedata}?zone_id=${zone}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                setRows(res.data);
                console.log(res.data)

                setLoading(false);
                if (res.data <= 0) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Please try again',
                        text: `ไม่พบข้อมูลรอบเวลาของวันที่ ${datedata} กรุณาเพิ่มข้อมูลและลองใหม่อีกครั้ง`
                    });
                } else {
                    Swal.close();
                }

            });
        console.log(Object.keys(localStorage).filter(key => localStorage.getItem(key) === 'clicked'));
        console.log(Object.keys(localStorage).filter(key => localStorage.getItem(key) === 'show'));

        const clickedItems = Object.keys(localStorage).filter(key => localStorage.getItem(key) === 'clicked');
        setNotifications(clickedItems.length);
        readdatashow()


    }

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const zoneId = queryParams.get('zone_id');

    useEffect(() => {
        console.log('zoneId' + zoneId)
        if (zoneId) {
            setValues({ ...values, ['zone']: zoneId })
        }

        loaddata(date, zoneId ? zoneId : values.zone)
        const token = localStorage.getItem('SavedToken')
        // axios.get(process.env.REACT_APP_BASE_API_URL + `/api/footset`, {
        //     headers: {
        //         Authorization: `Bearer ${token}`
        //     }
        // })
        //     .then(res => {
        //         setFootset(res.data.data); // Note the res.data.data
        //         console.log(res.data.data);


        //     }).catch(error => {
        //         console.error('Error fetching data:', error);
        //     });



    }, []);
    const handleSearch = () => {

        //Swal.fire('Error!', 'ยังไม่ได้ทำครับ', 'error')

        navigate(`/booking/${formatDate(values.date)}`);
        loaddata(formatDate(values.date), values.zone)
    }

    // const formattedDate = format(new Date(date), 'วันที่ dd MMMM yyyy', { locale: th });


    function clearSpecificButtonStorage(date, zoneid, aroundid, index, zone, around) {
        const buttonKey = `${date}|${zoneid}|${aroundid}|${index}`;
        const buttonKey_show = `${date}|${zone}|${around}|${index}`;

        localStorage.removeItem(buttonKey);
        localStorage.removeItem(buttonKey_show);
        const clickedItems = Object.keys(localStorage).filter(key => localStorage.getItem(key) === 'clicked');
        setNotifications(clickedItems.length);
        readdatashow()

        handleClickVariant('ยกเลิกคิวการจองสำเร็จ', 'error')();
        setClickedButtons(prevState => ({
            ...prevState,
            [buttonKey]: false
        }));
    }


    function handleClick(date, zoneid, aroundid, index, zone, around) {
        const buttonKey = `${date}|${zoneid}|${aroundid}|${index}`;
        const buttonKey_show = `${date}|${zone}|${around}|${index}`;
        if (localStorage.getItem(buttonKey)) {
            alert('This button has been clicked before');
        } else {
            handleClickVariant('เพิ่มคิวการจองสำเร็จ', 'success')();
            localStorage.setItem(buttonKey, 'clicked');
            localStorage.setItem(buttonKey_show, 'show');
            readdatashow()

            const clickedItems = Object.keys(localStorage).filter(key => localStorage.getItem(key) === 'clicked');
            setNotifications(clickedItems.length);
            setClickedButtons(prevState => ({
                ...prevState,
                [buttonKey]: true
            }));
        }
    }


    const handleOpenDialog = () => {
        setIsOpen(true);
        console.log(items)

        const randomString = generateRandomString(10);
        //setUrlslipValues(`http://localhost:3000/uploadslip/${date}/${randomString}`);
        setUrlslipValues(`http://reservation.bubblethailand.com/uploadslip/${date}/${randomString}`);

        setRefcode(randomString);


    };

    const handleCloseDialog = () => {
        console.log(items)
        setIsOpen(false);
        setUrlslipValues('-');
        setRefcode('-');

    };



    const [fileName, setFileName] = useState("");

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
        }
    };

    const submitBooking = async (e) => {

        e.preventDefault();
        // console.log(values)
        if (values.customer_name && values.count_cus > 0) {
            setIsOpen(false);
            try {
                const data = {
                    "reserve_detail_customer_name": values.customer_name,
                    "reserve_detail_customer_tel": values.customer_tel,
                    "reserve_detail_footsetid": values.setfoot,
                    "reserve_detail_footcnt": values.count_foot,
                    "reserve_detail_count": values.count_cus,
                    "reserve_detail_deposit": values.deposit,
                    "reserve_detail_note": values.note,
                    "reserve_detail_status": values.paid ? "2" : "0",
                    "reserve_detail_empinsert": localStorage.getItem("user"),
                    "reserve_detail_urlslipt": urlslipValues,
                    "reserve_detail_pathslip": "/test/......",
                    "reserve_detail_date": date,
                    "reserve_detail_refcode": refcode,
                    "reserve_detail_group": itemShow.map((item, index) => ({
                        "aroundid": item.timeid,
                        "zoneid": item.zoneid
                    }))
                };

                console.log(data)
                Swal.fire({
                    title: 'Loading...',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                });
                const token = localStorage.getItem('SavedToken');

                await axios
                    .post(process.env.REACT_APP_BASE_API_URL + "/api/booking/", data, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }

                    })
                    .then((response) => {
                        console.log("response booking", response);
                        if (response.data.message === "Success") {
                            loaddata(date, values.zone)
                            Swal.fire({
                                icon: "success",
                                title: "Booking success",
                                text: response.data.message,
                            });
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: "",
                                text: response.data.detail
                            });
                        }
                    });
            } catch (error) {
                console.log(error);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error.response && error.response.data && error.response.data.detail
                        ? error.response.data.detail
                        : "ไม่สามารถติดต่อ server ได้",
                });
            }
            //window.location.href = 'http://localhost:3000/dashboard'
            //navigate("/dashboard");

        } else {

            if (!values.customer_name) {
                Swal.fire({
                    icon: "error",
                    title: "Missing Information",
                    text: "กรุณาใส่ข้อมูลลูกค้า",
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Missing Information",
                    text: "กรุณาระบุจำนวนลูกค้า",
                });
            }

        }

    };

    function formatTime(timeString) {
        let times = timeString.split(' - ');
        let startTime = times[0].substring(0, 5);
        let endTime = times[1].substring(0, 5);
        return (
            <>
                {startTime}
                <br />
                <br />
                {endTime}
            </>
        );
    }

    const generateRandomString = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;

        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
    };

    const randomString = generateRandomString(5);




    return (

        <>

            <HeaderContainer>
                <Typography variant="h5" fontWeight="700">
                    การจองคิวล่วงหน้า
                </Typography>

            </HeaderContainer>
            <Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleCloseDialog}>
                <DialogTitle sx={{ fontSize: 24 }} fontWeight="700">สรุปการจองจองคิว {date} </DialogTitle>
                <DialogContent>
                    {items.length > 0 ?
                        <Row >
                            <Col xs={12} md={4} >
                                <Typography variant="h6" fontWeight="700" sx={{ marginBottom: 1 }}>ข้อมูลการจอง</Typography>

                                {items.map((item, index) => (
                                    <div key={index}>
                                        <Typography variant="h6">โซน : {item.zone}</Typography>
                                        <Typography variant="h6">รอบ : {item.time}</Typography>
                                        <Typography variant="h6">จำนวน : {item.quantity} โต๊ะ</Typography>

                                        <Divider sx={{ marginBottom: 2, marginTop: 2 }} />
                                    </div>
                                ))}

                                <Col>
                                    <Typography variant="h6">มีจำนวนทั้งหมด: {items.reduce((total, item) => total + item.quantity, 0)} โต๊ะ</Typography>
                                </Col>

                            </Col>
                            <Col xs={12} md={8}>
                                <Typography variant="h6" fontWeight="700" sx={{ marginBottom: 1 }}>ข้อมูลลูกค้า</Typography>


                                <Row>
                                    <Col xs={12} md={6}>
                                        <FormControl sx={{ m: 1, width: "100%" }}>
                                            <TextField
                                                id="customer_name"
                                                label="ชื่อลูกค้า"
                                                defaultValue={values.customer_name}
                                                onChange={handleChangeData("customer_name")}
                                                fullWidth
                                                required
                                            />
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <FormControl sx={{ m: 1, width: "100%" }}>
                                            <TextField
                                                id="customer_tel"
                                                label="เบอร์โทร"
                                                defaultValue={values.customer_tel}
                                                onChange={handleChangeData("customer_tel")}
                                                fullWidth
                                                required
                                            />
                                        </FormControl>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={12} md={6}>
                                        <FormControl sx={{ m: 1, width: "100%" }}>
                                            <TextField
                                                id="count_cus"
                                                label="จำนวนคน"
                                                variant="standard"
                                                type="number"
                                                defaultValue={values.count_cus}
                                                onChange={handleChangeData("count_cus")}
                                                fullWidth
                                                required
                                            />
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <FormControl sx={{ m: 1, width: "100%" }}>
                                            <TextField
                                                id="deposit"
                                                label="จำนวนเงินมัดจำ"
                                                variant="standard"
                                                type="number"
                                                value={values.deposit}
                                                onChange={handleChangeData("deposit")}
                                                // InputProps={{
                                                //     readOnly: true,
                                                // }}
                                                fullWidth
                                            />
                                        </FormControl>
                                    </Col>
                                    {/* <Col>
                                        <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
                                            <InputLabel id="setfoot">เซ็ตอาหาร</InputLabel>
                                            <Select
                                                labelId="setfoot"
                                                id="setfoot"
                                                value={values.setfoot}
                                                onChange={handleChangeData("setfoot")}
                                                label="เซ็ตอาหาร"
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {footsets.map((item, index) => (
                                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                                ))}

                                            </Select>
                                        </FormControl>
                                    </Col> */}
                                    {/* <Col>
                                        <FormControl sx={{ m: 1, width: "100%" }}>
                                            <TextField
                                                id="count_foot"
                                                label="จำนวนอาหาร"
                                                variant="standard"
                                                type="number"
                                                value={values.count_foot}
                                                onChange={handleChangeData("count_foot")}
                                                fullWidth
                                                disabled={!values.setfoot}
                                            />
                                        </FormControl>
                                    </Col> */}

                                    <Col>
                                        <FormControl sx={{ m: 1, width: "100%" }}>
                                            <TextField
                                                id="note"
                                                label="หมายเหตุ"
                                                variant="standard"
                                                value={values.note}

                                                onChange={handleChangeData("note")}
                                                fullWidth
                                            />
                                        </FormControl>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col>
                                        <FormControl sx={{ m: 1, marginTop: 2, width: "100%" }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={values.paid} onChange={handleChangeData("paid")} name="paid" />
                                                }
                                                label="ชำระเงินแล้ว"
                                            />
                                        </FormControl>
                                    </Col>
                                    <Col>
                                        {/* Show "Upload file" UI if "paid" is true */}
                                        {values.paid && (
                                            <FormControl sx={{ m: 1, marginTop: 2, width: "100%" }}>
                                                <input
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    id="raised-button-file"
                                                    type="file"
                                                    onChange={handleFileChange}
                                                />
                                                <label htmlFor="raised-button-file">
                                                    <Button variant="contained" component="span">
                                                        Upload
                                                    </Button>
                                                </label>
                                                {fileName && <p>Uploaded file: {fileName}</p>}
                                            </FormControl>
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {/* Show "urlpay" UI if "paid" is false */}
                                        {!values.paid && (
                                            <FormControl sx={{ m: 1, width: "100%" }}>
                                                <TextField
                                                    id="urlpay"
                                                    label="url สำหรับ Upload สลิป"
                                                    variant="filled"
                                                    defaultValue="-"
                                                    required
                                                    value={urlslipValues}
                                                    InputProps={{
                                                        readOnly: true,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <Button onClick={handleCopy}>
                                                                    <CopyIcon />
                                                                </Button>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    fullWidth
                                                    inputRef={textRef}
                                                />
                                            </FormControl>
                                        )}
                                    </Col>
                                </Row>



                            </Col>

                        </Row>
                        : <Typography variant="h6" >ไม่พบข้อมูลการเพิ่มคิว</Typography>}

                </DialogContent>
                <DialogActions>
                    {items.length > 0 ?
                        <Button style={{ fontSize: 20 }} onClick={submitBooking} color="primary">ยืนยันการจอง</Button>
                        : ''}
                    <Button style={{ fontSize: 20 }} onClick={handleCloseDialog} color="primary">Close</Button>
                </DialogActions>
            </Dialog>
            <Paper sx={{ padding: "1rem", marginTop: "-3rem" }}>
                <Typography fontWeight="700" variant="h6">ค้นหาระบุ</Typography>

                <form >
                    <Row>
                        <Col xs={6} md={3}>
                            <FormControl sx={{ m: 1, width: "100%" }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="วันที่"
                                        value={values.date} // access the date value from the state
                                        onChange={handleChangeData('date')} // update the date value in the state
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Col>

                        <Col xs={6} md={3}>
                            <FormControl sx={{ m: 1, width: "100%" }}>
                                <InputLabel id="zone">เลือกโซน</InputLabel>
                                <Select
                                    labelId="zone"
                                    id="searchZone"
                                    label="เลือกโซน"
                                    value={values.zone}
                                    onChange={handleChangeData('zone')}
                                >
                                    <MenuItem
                                        value="%" defaultValue>ALL</MenuItem>
                                    {zones.map((item, index) => (
                                        <MenuItem key={item.zone_id} value={item.zone_id}>{item.zone_name}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                        </Col>

                        <Col xs={12} md={4}></Col>

                        <Col xs={12} md={2}>
                            <FormControl sx={{ m: 1, width: "100%" }}>
                                <Button variant="contained" size="large" onClick={handleSearch}>
                                    ค้นหา
                                </Button>
                            </FormControl>
                        </Col>


                    </Row>

                </form>
            </Paper>

            <>

                <Row style={{ marginTop: "1rem" }} >
                    <Col xs={12} md={5}>
                        <Typography variant="h5" fontWeight="700">
                            รายการการจอง | วันที่ {date}
                        </Typography>

                        <Typography display="inline" variant="body2" style={{ color: "#000" }} > <FiberManualRecordIcon style={{ color: "#EB5353" }} /> ยืนยันแล้ว </Typography>
                        <Typography display="inline" variant="body2" style={{ color: "#AAAAAA" }} > | </Typography>
                        <Typography display="inline" variant="body2" style={{ color: "#000" }} > <FiberManualRecordIcon style={{ color: "#FED523" }} /> รอการยืนยัน  </Typography>
                        <Typography display="inline" variant="body2" style={{ color: "#AAAAAA" }} > | </Typography>
                        <Typography display="inline" variant="body2" style={{ color: "#000" }} > <FiberManualRecordIcon style={{ color: "#9FA1A1" }} /> ไม่สามารถจองคิวได้  </Typography>
                        <Typography display="inline" variant="body2" style={{ color: "#AAAAAA" }} > | </Typography>
                        <Typography display="inline" variant="body2" style={{ color: "#000" }} > <FiberManualRecordIcon style={{ color: "#07B53B" }} /> ว่าง </Typography>
                    </Col>
                    <Col xs={12} md={4}>
                        <IconButton onClick={handleOpenDialog} color="inherit" className={classes_icon.squareButton}>
                            <Badge badgeContent={notifications} color={notifications > 0 ? "secondary" : "primary"} classes={{ badge: classes_icon.badge }}>
                                <Typography variant="h6" fontWeight="700">
                                    เพิ่มการจอง
                                </Typography>
                                <NotificationsIcon style={{ fontSize: '40px' }} />
                            </Badge>
                        </IconButton>

                    </Col>
                    <Col xs={12} md={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="contained" size="large" style={{
                            backgroundColor: "#07B53B",
                            width: '160px',
                            fontSize: '18px'
                            , marginRight: '4px'
                        }}
                            onClick={handleBackDate}
                        >                            <KeyboardDoubleArrowLeftIcon />

                            วันก่อนหน้า
                        </Button>
                        <Button variant="contained" size="large" style={{
                            backgroundColor: "#07B53B",
                            width: '160px',
                            fontSize: '18px'
                            , marginRight: '4px'
                        }}
                            onClick={handleNextDate}

                        >
                            วันถัดไป
                            <KeyboardDoubleArrowRightIcon />
                        </Button>
                    </Col>
                </Row>
                <Row style={{ marginTop: "1rem" }}>
                    <Col xs={12} md={12}>

                    <TableContainer component={Paper}>
                            {rows.map((row, rowIndex) => {
                                const maxTotals = Math.max(...row.zone.map(zone => zone.total));
                                return (
                                    <Table key={rowIndex}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" style={{ border: '1px solid #AAA', width: '4%', height: '50px', fontSize: 18 }}>Around</TableCell>
                                                {row.zone.map((zone) => (
                                                    <TableCell align="right" style={{ border: '1px solid #AAA', width: '12%', height: '50px', fontSize: 18 }}>
                                                        {zone.zone} <br /> ({zone.use} / {zone.total})
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Array.from({ length: maxTotals }).map((_, index) => (
                                                <TableRow
                                                    key={`${row.around}|${index}`}
                                                    style={{ height: 60 }}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    {index === 0 && (
                                                        <TableCell align="center" component="th" scope="row" rowSpan={maxTotals} style={{ border: '1px solid #AAA', fontSize: 18 }}>
                                                            {formatTime(row.around)}
                                                        </TableCell>
                                                    )}
                                                    {row.zone.map((zone) => (
                                                        <TableCell align={index < zone.total ? "center" : "right"} style={{
                                                            border: '1px solid #AAA',
                                                            backgroundColor: index < zone.row.length
                                                                ? (zone.row[index].status === "ยืนยันการจอง"
                                                                    ? 'none' : zone.row[index].name ? 'none' : 'none')
                                                                : (index < zone.total ? '#fff' : '#CCD1D1')
                                                        }}>
                                                            {index < zone.row.length ? (
                                                                <Card style={{
                                                                    borderRadius: 10, backgroundColor: index < zone.row.length
                                                                        ? (zone.row[index].status === "ยืนยันการจอง"
                                                                            ? '#EB5353' : zone.row[index].name ? '#FED523' : '')
                                                                        : (index < zone.total ? '#fff' : '#CCD1D1'), color: index < zone.row.length
                                                                            ? (zone.row[index].status === "ยืนยันการจอง"
                                                                                ? '#fff' : zone.row[index].name ? '#000' : '')
                                                                            : (index < zone.total ? '#fff' : '#CCD1D1'), height: '30px', width: '100%', padding: "-2rem"
                                                                }}
                                                                    onClick={() => {
                                                                        setDialogContent({
                                                                            name: zone.row[index].name,
                                                                            tel: zone.row[index].tel,
                                                                            count: zone.row[index].count,
                                                                            note: zone.row[index].note,
                                                                            status: zone.row[index].status,
                                                                            setfoot: zone.row[index].setfoot
                                                                        });
                                                                        setDialogOpen(true);
                                                                    }}>
                                                                    <CardContent
                                                                        sx={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            height: '100%'
                                                                        }}
                                                                    >
                                                                        <Typography align="center" variant="body2" component="p">
                                                                            {zone.row[index].name}
                                                                        </Typography>
                                                                    </CardContent>
                                                                </Card>
                                                            ) : index < zone.total && formatDate(date) >= formatDate(nowDate) ?
                                                                <>
                                                                    {!clickedButtons[`${date}|${zone.zoneid}|${row.aroundid}|${index}`] ?
                                                                        <Button size="small" style={{
                                                                            backgroundColor: clickedButtons[`${date}|${zone.zoneid}|${row.aroundid}|${index}`] ? "#A5D6A7" : "#07B53B",
                                                                            color: "#fff"

                                                                        }}
                                                                            onClick={() => {

                                                                                handleClick(date, zone.zoneid, row.aroundid, index, zone.zone, row.around);

                                                                            }}
                                                                            disabled={clickedButtons[`${date}|${zone.zoneid}|${row.aroundid}|${index}`]}
                                                                        >
                                                                            เพิ่มคิว
                                                                        </Button>

                                                                        : <Button size="small" style={{
                                                                            backgroundColor: !clickedButtons[`${date}|${zone.zoneid}|${row.aroundid}|${index}`] ? "#FFC1C1" : "#EB5353",
                                                                            color: "#fff"
                                                                        }}
                                                                            disabled={!clickedButtons[`${date}|${zone.zoneid}|${row.aroundid}|${index}`]}

                                                                            onClick={() => clearSpecificButtonStorage(date, zone.zoneid, row.aroundid, index, zone.zone, row.around)}
                                                                        >
                                                                            ยกเลิกคิว
                                                                        </Button>}
                                                                </>
                                                                : ''}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                );
                            })}
                        </TableContainer>

                    </Col>

                </Row>
                <Dialog
                    fullWidth
                    maxWidth="xs"
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle style={{ cursor: 'move', fontSize: 26 }} id="alert-dialog-title">รายละเอียด</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography display="inline" variant="h6" >ชื่อลูกค้า: {dialogContent?.name} </Typography> <br></br>
                            <Typography display="inline" variant="h6" >หมายเหตุ: {dialogContent?.note ? dialogContent?.note : '-'} </Typography> <br></br>
                            <Typography display="inline" variant="h6" >เซ็ตอาหาร: {dialogContent?.setfoot ? dialogContent?.setfoot : '-'} </Typography> <br></br>
                            <Typography display="inline" variant="h6" >สถานะ: {dialogContent?.status} </Typography> <br></br>

                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>

                        <Button variant="contained" size="large" onClick={() => setDialogOpen(false)} >
                            ตกลง
                        </Button>

                    </DialogActions>
                </Dialog>


            </>

        </>
    );
};

export default function IntegrationNotistack() {
    return (
        <SnackbarProvider maxSnack={3}>
            <Booking />
        </SnackbarProvider>
    );
}
