import React from "react";
import { format, addDays } from 'date-fns';

import {
  Drawer,
  Toolbar,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Typography,
  Stack,
  IconButton,
  Button,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, Link } from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CancelIcon from "@mui/icons-material/Cancel";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import Header from "./header";
import Avartar from "./avartar";
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';



//const menu = menu_admin;
class SidebarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      boderRadius: 2,
      fontSize: 16,
      zIndex: 1000,
      isOpen: false, // <-- สถานะของ Sidebar (เปิดหรือปิด)

    };
  }



  componentDidMount() {
    const { location } = this.props;
    if (location.pathname.startsWith("/booking/")) {
      this.setState({ isOpen: false });
    }
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (prevProps.open !== this.props.open) {
      this.setState({
        isOpen: this.props.open
      });
    }

    if (prevProps.location.pathname !== location.pathname && location.pathname.startsWith("/booking/")) {
      this.setState({ isOpen: false });
    } else if (
      prevProps.location.pathname.startsWith("/booking/") &&
      !location.pathname.startsWith("/booking/")
    ) {
      this.setState({ isOpen: true });
    }
  }


  render() {

    const menu_admin = [];

    const currentDate = new Date();
    const endDate = addDays(currentDate, 6);

    const startDateParam = format(currentDate, 'yyyy-MM-dd');
    const endDateParam = format(endDate, 'yyyy-MM-dd');

    const path = `/day/${startDateParam}/${endDateParam}`;


    menu_admin.push(
      {
        path: "/",
        icon: "fa-solid fa-calendar-days",
        title: "การจองรายเดือน",
        child: [],
      }, {
      path: path,
      icon: "fa-solid fa-calendar-days",
      title: "การจองรายสัปดาห์",
      child: [],
    }, {
      path: "/bookinglist",
      icon: "fa-solid fa-sheet-plastic",
      title: "รายการจองคิว",
      child: [],
    }, {
      path: "",
      icon: "fa-solid fa-bell",
      title: "การแจ้งเตือน",
      child: [
        {

          path: "/bookinglist/info",
          icon: "fa-solid fa-file-invoice-dollar",
          title: "แจ้งเตือนการโอน",
          child: [],
        }, {
          path: "/bookinglist/warn",
          icon: "fa-solid fa-circle-exclamation",
          title: "รอการชำระเงิน",
          child: [],
        }, {
          path: "/bookinglist/error",
          icon: "fa-solid fa-triangle-exclamation",
          title: "เกิดกำหนดชำระ",
          child: [],
        }, 
      ],
    },  {
      path: "",
      icon: "fa-solid fa-chart-pie",
      title: "รายงาน",
      child: [
        {

          path: "/report/store/Select",
          icon: "fa-solid fa-store",
          title: "หน้าร้าน",
          child: [],
        },
        //  {
        //   path: "/report/kitchen/Select",
        //   icon: "fa-solid fa-kitchen-set",
        //   title: "ในครัว",
        //   child: [],
        // }
      ],
    },{
      path: "",
      icon: "fa-solid fa-toolbox",
      title: "จัดการข้อมูล",
      child: [
        // {
        //   path: "/manage/customer",
        //   icon: "fa-solid fa-users",
        //   title: "จัดการลูกค้า",
        // },
        // {
        //   path: "/manage/zone",
        //   icon: "fa-solid fa-map-location-dot",
        //   title: "จัดการโซน",
        // },
        // {
        //   path: "/manage/sessions",
        //   icon: "fa-solid fa-money-bill-1",
        //   title: "การชำระเงิน",
        // },
        {
          path: "/manage/cycletime",
          icon: "fa-solid fa-clock",
          title: "รอบเวลา",
        },
        {
          path: "/manage/group",
          icon: "fa-solid fa-solid fa-calendar-day",
          title: "การจองต่อวัน",
        }
      ],
    },
      // {
      //   path: "/setting",
      //   icon: "fa-solid fa-cog ",
      //   title: "ตั้งค่าบัญชี",
      //   child: [],
      // }
      );

    const menu = menu_admin;

    console.log(menu);

    const { location, width, open, drawerwidth } = this.props;
    console.log(this.props);
    const isOpen = this.state.isOpen && open;

    const logout = () => {
      localStorage.clear()
      window.location.href = "/login";
    };

    const CollapseMenu = (props) => {
      const { data } = props;
      const [open, setOpen] = React.useState(false);

      React.useEffect(() => {
        if (data?.child.some((val) => val?.path === location.pathname)) {
          setOpen(true);
        } 
      }, [data?.child]);

      return (
        <React.Fragment sx={{ zIndex: 1000 }}>
          <ListItem>
            <ListItemButton
              onClick={() => setOpen(!open)}
              sx={{ borderRadius: this.state.boderRadius, zIndex: 1000 }}
            >
              <ListItemIcon>
                <FontAwesomeIcon
                  icon={data?.icon}
                  style={{ color: "#272D43", zIndex: 1000 }}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    sx={{ fontSize: this.state.fontSize, fontWeight: "bold", zIndex: 1000 }}
                  >
                    {data?.title}
                  </Typography>
                }
              />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {data?.child.map((child, _index) => {
              return (
                <ListItem sx={{ pl: 4, pt: 0 }} key={_index}>
                  <ListItemButton
                    onClick={
                      this.props.size.width <= this.props.breakwidth
                        ? this.props.control
                        : null
                    }
                    component={location.pathname === child?.path ? "" : Link}
                    to={location.pathname === child?.path ? "" : child?.path}
                    selected={location.pathname === child?.path ? true : false}
                    sx={{
                      pl: 4,
                      py: 0,
                      minHeight: 50,
                      zIndex: 1000,
                      borderRadius: this.state.boderRadius,
                      ...listStyles,
                    }}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon
                        icon={child?.icon}
                        style={{
                          color: "#2D2D2D",
                          zIndex: 1000,
                          fontSize:
                            child?.icon === "fa-solid fa-circle"
                              ? "10px"
                              : "20px",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          variant="h6"
                          sx={{ fontSize: 15, fontWeight: "bold", zIndex: 1000 }}
                        >
                          {child?.title}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </Collapse>
        </React.Fragment>
      );
    };

    return (
      <Drawer
        open={
          this.props.size.width <= this.props.breakwidth
            ? this.props.open && this.state.isOpen
            : this.props.open
        }
        variant={
          this.props.size.width < this.props.breakwidth
            ? "temporary"
            : "persistent"
        }
        anchor="left"
        onClose={this.props.slideBarOnClick}
        sx={{
          zIndex: 1000,
          width: drawerwidth,

          [`& .MuiDrawer-paper`]: {
            width: drawerwidth,
            boxSizing: "border-box",
          },

        }}


      >
        <Header slideBarOnClick={this.props.slideBarOnClick} style={{ zIndex: 1000 }} />
        <Avartar />
        <Box
          sx={{
            overflow: "auto",
            // marginTop: 1.5,
            justifyContent: "space-between",
            zIndex: 1000
          }}
        >
          <List>
            {menu.map((data, index) =>
              data?.child.length > 0 ? (
                <CollapseMenu data={data} key={index} />
              ) : (
                <ListItem key={index}>
                  <ListItemButton
                    onClick={
                      this.props.size.width <= this.props.breakwidth
                        ? this.props.control
                        : null
                    }
                    component={location.pathname === data?.path ? "" : Link}
                    to={location.pathname === data?.path ? "" : data?.path}
                    selected={location.pathname === data?.path ? true : false}
                    sx={{ borderRadius: this.state.boderRadius, ...listStyles }}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon
                        fixedWidth
                        icon={data?.icon}
                        size="lg"
                        style={{ color: "#272D43" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: this.state.fontSize,
                            fontWeight: "bold",
                          }}
                        >
                          {data?.title}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              )
            )}
          </List>
          <div
            style={{

              position: "absolute",
              bottom: 0,
              right: 10,
              left: 10,
              paddingBottom: 10,
              zIndex: 1000,
            }}
          >
            <Typography
              gutterBottom
              variant="caption"
              display="block"
              sx={{ pl: 1 }}
            >
              1.0.0 Alpha by pattharapol
            </Typography>
            <Button
              fullWidth
              variant="contained"
              startIcon={<LogoutOutlinedIcon />}
              color="primary"
              onClick={logout}

            >
              ออกจากระบบ
            </Button>
          </div>
        </Box>
      </Drawer>
    );
  }
}

const Sidebar = (props) => {
  let location = useLocation();

  return <SidebarComponent sx={{ zIndex: 1000 }} location={location} {...props} />;
};

export default Sidebar;

const listStyles = {
  color: "#272D43",

  "&.Mui-selected": {
    backgroundColor: "#F3F4F6",
  },
};
