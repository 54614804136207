import React, { useState, useEffect, useRef } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import ReactTable from "react-table-6";
import "react-table-6/react-table.css"
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from '@mui/icons-material/Cancel';
import { TablePagination } from '@mui/material';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { format } from 'date-fns';
import { th } from 'date-fns/locale';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';

import { AdapterLuxon, DateTime } from '@mui/lab/AdapterLuxon';
import { DateTime as LuxonDateTime } from 'luxon';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { makeStyles } from '@mui/styles';
import { SnackbarProvider, useSnackbar } from 'notistack';


import {
    Badge,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    TextField,
    Box,
    Divider,
    Checkbox,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    IconButton,
    Card, CardContent,
    FormControlLabel,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,

} from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import styled from "styled-components";
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';

import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import CopyIcon from '@mui/icons-material/FileCopy';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs'; // นำเข้า Day.js
import { addDays, isWithinInterval, startOfDay } from 'date-fns';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
const MAX_RANGE_DAYS = 7;

const currentDate = new Date();
const endDate = addDays(currentDate, 5);

const startDateParam = format(currentDate, 'yyyy-MM-dd');


const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top:  -1em;
  margin-bottom: 2em;
`;

const useStyles = makeStyles((theme) => ({
    badge: {
        fontSize: 14
    }
}));

const Booking = () => {
    document.title = 'การจองรายสัปดาห์ - ระบบการจองคิวล่วงหน้า (Advance booking system)';

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [zones, setZones] = useState([]);

    const [rows, setRows] = useState([]);


    const [customers, setCustomers] = useState([
        {
            id: 'CUST000001',
            name: 'กรุ๊ปทัว 1234',
            phoneNumber: '091234657',
        },
        {
            id: 'CUST000002',
            name: 'คุณลิง4',
            phoneNumber: '091122612',
        }
    ]);


    const [items, setItems] = useState([]);


    const readdatashow = () => {
        const data = Object.keys(localStorage)
            .filter(key => localStorage.getItem(key) === 'show')
            .map(key => key.split('|'));

        const groupedData = data.reduce((acc, curr) => {
            const key = `${curr[0]}|${curr[1]}|${curr[2]}`;
            if (!acc[key]) {
                acc[key] = {
                    date: curr[0],
                    zone: curr[1],
                    time: curr[2],
                    quantity: 1
                };
            } else {
                acc[key].quantity += 1;
            }
            return acc;
        }, {});

        setItems(Object.values(groupedData));
    }



    const { startdate, enddate } = useParams();

    const currentDatestart = new Date(startdate);
    const currentDateend = new Date(enddate);

    const nextWeekstart = addDays(currentDatestart, 7);
    const nextWeekend = addDays(currentDatestart, 13);

    const backWeekstart = addDays(currentDateend, -13);
    const backWeekend = addDays(currentDateend, -7);


    const nextWeekdatastart = format(nextWeekstart, 'yyyy-MM-dd');
    const nextWeekdataend = format(nextWeekend, 'yyyy-MM-dd');

    const backWeekdatastart = format(backWeekstart, 'yyyy-MM-dd');
    const backWeekdataend = format(backWeekend, 'yyyy-MM-dd');


    const handleNextWeek = () => {
        navigate(`/day/${nextWeekdatastart}/${nextWeekdataend}`);
        loaddata(nextWeekdatastart, nextWeekdataend, values.zone)
        setValues(prevValues => ({ ...prevValues, datestart: nextWeekdatastart }));
        setValues(prevValues => ({ ...prevValues, dateend: nextWeekdataend }));



    };
    const handleBackWeek = () => {
        navigate(`/day/${backWeekdatastart}/${backWeekdataend}`);
        loaddata(backWeekdatastart, backWeekdataend, values.zone)
        setValues(prevValues => ({ ...prevValues, datestart: backWeekdatastart }));
        setValues(prevValues => ({ ...prevValues, dateend: backWeekdataend }));


    };

    const [xrayValues, setxrayValues] = useState([]);
    const [loading, setLoading] = useState(true);
    const textRef = useRef();


    const [dialogOpen, setDialogOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false); // สถานะเพื่อกำหนดว่า Dialog ควรจะแสดงหรือไม่
    const [dialogContent, setDialogContent] = useState(null);


    const [selectedCustomer, setSelectedCustomer] = useState('');
    const handleCustomerSelect = (event, value) => {
        setSelectedCustomer(value ? value : '');
    };

    const handleCopy = () => {
        if (textRef.current) {
            navigator.clipboard.writeText(textRef.current.value);
        }
    };

    const [values, setValues] = useState({
        datestart: startdate,
        dateend: enddate,
        count_cus: 0,
        deposit: 0,
        paid: false,
        zone: "%"
    });

    const handleChangeData = (prop) => (event) => {
        if (prop === 'paid') {
            setValues({ ...values, [prop]: event.target.checked });
        } else if (prop === 'count_cus') {
            let value = event.target.value;
            let deposit = value * 300;
            setValues({ ...values, 'count_cus': value, 'deposit': deposit });
        } else if (prop === 'datestart') {
            let value = event;
            setValues({ ...values, [prop]: value });
        } else if (prop === 'dateend') {
            let value = event;
            setValues({ ...values, [prop]: value });
        } else {
            let value = event.target.value;

            setValues({ ...values, [prop]: value });
        }


    };



    const loaddata = (startdate, enddate, zone) => {
        const token = localStorage.getItem('SavedToken');
        Swal.fire({
            title: 'Loading...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });
        axios.get(process.env.REACT_APP_BASE_API_URL + `/api/booking/${startdate}/${enddate}?zone_id=${zone}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                setRows(res.data);
                console.log(res.data)

                setLoading(false);
                if (res.data <= 0) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Please try again',
                        text: `ไม่พบข้อมูลรอบเวลาของวันที่ ${startdate} - ${enddate}กรุณาเพิ่มข้อมูลและลองใหม่อีกครั้ง`
                    });
                } else {
                    Swal.close();
                }
            })



    }
    useEffect(() => {
        loaddata(startdate, enddate, values.zone)
        const token = localStorage.getItem('SavedToken');

        axios.get(process.env.REACT_APP_BASE_API_URL + `/api/zone/`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                setZones(res.data);
                console.log(res.data)

                setLoading(false);

                //Swal.close();


            });
    }, []);


    // const formattedDate = format(new Date(date), 'วันที่ dd MMMM yyyy', { locale: th });



    const handleOpenDialog = () => {
        setIsOpen(true);
        setSelectedCustomer('')
    };

    const handleCloseDialog = () => {
        setIsOpen(false);
    };

    const [fileName, setFileName] = useState("");

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
        }
    };

    const aggregatedRows = rows.map((row) => {
        const aroundMap = {};

        row.around.forEach((around) => {
            if (!aroundMap[around.around]) {
                aroundMap[around.around] = [];
            }

            aroundMap[around.around].push(...around.row);
        });

        const aggregatedAround = Object.entries(aroundMap).map(([around, row]) => ({
            around,
            row,
        }));

        return {
            ...row,
            around: aggregatedAround,
        };
    });
    const handleSearch = () => {
        //Swal.fire('Error!', 'ยังไม่ได้ทำครับ', 'error')
        navigate(`/day/${formatDate(values.datestart)}/${formatDate(values.dateend)}`);
        loaddata(formatDate(values.datestart), formatDate(values.dateend), values.zone)
    }


    const formatDate = (date) => {
        const newDate = new Date(date);
        const year = newDate.getFullYear();
        const month = String(newDate.getMonth() + 1).padStart(2, '0');
        const day = String(newDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    function formatTime(timeString) {
        if (!timeString || typeof timeString !== 'string') {
          return 'Invalid time';
        }
      
        let times = timeString.split('-');
      
        if (times.length !== 2) {
          return 'Invalid time format';
        }
      
        let startTime = times[0].substring(0, 5);
        let endTime = times[1].substring(0, 5);
      
        return (
          <>
            {startTime}
            <br />
            <br />
            {endTime}
          </>
        );
      }
      

    
    return (

        <>

            <HeaderContainer>
                <Typography variant="h5" fontWeight="700">
                    การจองคิวล่วงหน้า
                </Typography>

            </HeaderContainer>

            <Paper sx={{ padding: "1rem", marginTop: "-3rem" }}>
                <Typography fontWeight="700" variant="h6">ค้นหาระบุ</Typography>

                <form >
                    <Row>

                        <Col xs={6} md={2}>
                            <FormControl sx={{ m: 1, width: "100%" }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="วันที่เริ่ม"
                                        value={values.datestart} // access the date value from the state
                                        onChange={handleChangeData('datestart')} // update the date value in the state
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>

                        </Col>
                        <Col xs={6} md={2}>
                            <FormControl sx={{ m: 1, width: "100%" }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="วันสิ้นสุด"
                                        value={values.dateend} // access the date value from the state
                                        onChange={handleChangeData('dateend')} // update the date value in the state
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>

                        </Col>

                        <Col xs={12} md={2}>
                            <FormControl sx={{ m: 1, width: "100%" }}>
                                <InputLabel id="zone">เลือกโซน</InputLabel>
                                <Select
                                    labelId="zone"
                                    id="searchZone"
                                    label="เลือกโซน"
                                    value={values.zone}
                                    onChange={handleChangeData('zone')}
                                >
                                    <MenuItem
                                        value="%" defaultValue>ALL</MenuItem>
                                    {zones.map((item, index) => (
                                        <MenuItem key={item.zone_id} value={item.zone_id}>{item.zone_name}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                        </Col>

                        <Col xs={0} md={4}>
                        </Col>

                        <Col xs={12} md={2}>
                            <FormControl sx={{ m: 1, width: "100%" }}>
                                <Button variant="contained" size="large" onClick={handleSearch} >
                                    ค้นหา
                                </Button>
                            </FormControl>
                        </Col>


                    </Row>

                </form>
            </Paper>

            <>

                <Row style={{ marginTop: "1rem" }} >
                    <Col xs={12} md={8}>
                        <Typography variant="h5" fontWeight="700">
                            รายการการจอง | วันที่ {startdate} - {enddate}
                        </Typography>

                        <Typography display="inline" variant="body2" style={{ color: "#000" }} > <FiberManualRecordIcon style={{ color: "#EB5353" }} /> ยืนยันแล้ว </Typography>
                        <Typography display="inline" variant="body2" style={{ color: "#AAAAAA" }} > | </Typography>
                        <Typography display="inline" variant="body2" style={{ color: "#000" }} > <FiberManualRecordIcon style={{ color: "#FED523" }} /> รอการยืนยัน  </Typography>
                        <Typography display="inline" variant="body2" style={{ color: "#AAAAAA" }} > | </Typography>
                        <Typography display="inline" variant="body2" style={{ color: "#000" }} > <FiberManualRecordIcon style={{ color: "#9FA1A1" }} /> ไม่สามารถจองคิวได้  </Typography>
                        <Typography display="inline" variant="body2" style={{ color: "#AAAAAA" }} > | </Typography>
                        <Typography display="inline" variant="body2" style={{ color: "#000" }} > <FiberManualRecordIcon style={{ color: "#07B53B" }} /> ว่าง </Typography>
                    </Col>
                    <Col xs={12} md={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="contained" size="large" style={{
                            backgroundColor: "#07B53B",
                            width: '2000px',
                            fontSize: '18px'
                            , marginRight: '4px'
                        }}
                            onClick={handleBackWeek}
                        >   <KeyboardDoubleArrowLeftIcon />
                            สัปดาห์ก่อนหน้า
                        </Button>
                        <Button variant="contained" size="large" style={{
                            backgroundColor: "#07B53B",
                            width: '2000px',
                            fontSize: '18px'
                            , marginRight: '4px'
                        }}
                            onClick={handleNextWeek}

                        >
                            สัปดาห์ถัดไป
                            <KeyboardDoubleArrowRightIcon />
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12}>


                        <TableContainer component={Paper}>
                            {aggregatedRows.map((row, rowIndex) => {
                                return (
                                    <>
                                        <Typography variant="h5" sx={{ margin: 2 }} fontWeight="700">
                                            {row.zone}
                                        </Typography>
                                        {row.around.map((around, aroundIndex) => {
                                            const maxTotals = Math.max(...around.row.map(dateRow => dateRow.total));
                                            return (
                                                <Table key={`${rowIndex}|${aroundIndex}`}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="center" style={{ border: '1px solid #AAA', width: '5%', height: '50px', fontSize: 18 }}>Around</TableCell>
                                                            {around.row.map((dateRow, i) => (
                                                                <TableCell align="right" style={{ border: '1px solid #AAA', width: '10%', height: '50px', fontSize: 16 }}>
                                                                    <Row>

                                                                        {formatDate(dateRow.date) >= startDateParam && dateRow.use < dateRow.total ?
                                                                            <Col>
                                                                                <Button
                                                                                    variant="contained"
                                                                                    size="large"
                                                                                    style={{
                                                                                        backgroundColor: "#07B53B",
                                                                                        marginRight: '18px'
                                                                                    }}
                                                                                    onClick={() => navigate(`/booking/${formatDate(dateRow.date)}?zone_id=${row.zoneid}`)}
                                                                                >
                                                                                    เพิ่มคิว
                                                                                </Button>
                                                                            </Col>
                                                                            : ''}

                                                                        <Col>
                                                                            {formatDate(dateRow.date)} <br /> ({dateRow.use} / {dateRow.total})
                                                                        </Col>
                                                                    </Row>
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {Array.from({ length: maxTotals }).map((_, index) => (
                                                            <TableRow
                                                                key={`${row.zone}|${around.around}|${index}`}
                                                                style={{ height: 60 }}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                {index === 0 && (
                                                                    <TableCell align="center" component="th" scope="row" rowSpan={maxTotals} style={{ border: '1px solid #AAA', fontSize: 18 }}>
                                                                        {formatTime(around.around)}
                                                                    </TableCell>
                                                                )}
                                                                {around.row.map((dateRow) => (
                                                                    <TableCell align={index < dateRow.total ? "center" : "right"} style={{
                                                                        border: '1px solid #AAA',
                                                                        backgroundColor: index < dateRow.detail.length
                                                                            ? (dateRow.detail[index].status === "ยืนยัน"
                                                                                ? 'none' : dateRow.detail[index].name ? 'none' : 'none')
                                                                            : (index < dateRow.total ? '#fff' : '#CCD1D1')
                                                                    }}>

                                                                        {index < dateRow.detail.length ? (
                                                                            <Card style={{
                                                                                borderRadius: 10, backgroundColor: index < dateRow.detail.length
                                                                                    ? (dateRow.detail[index].status === "ยืนยันการจอง"
                                                                                        ? '#EB5353' : dateRow.detail[index].name ? '#FED523' : '')
                                                                                    : (index < dateRow.total ? '#fff' : '#CCD1D1'), color: index < dateRow.detail.length
                                                                                        ? (dateRow.detail[index].status === "ยืนยันการจอง"
                                                                                            ? '#fff' : dateRow.detail[index].name ? '#000' : '')
                                                                                        : (index < dateRow.total ? '#fff' : '#CCD1D1'), height: '50px', width: '100%',
                                                                            }}
                                                                                onClick={() => {
                                                                                    setDialogContent({
                                                                                        name: dateRow.detail[index].name,
                                                                                        count: dateRow.detail[index].count,
                                                                                        note: dateRow.detail[index].note,
                                                                                        status: dateRow.detail[index].status,
                                                                                        setfoot: dateRow.detail[index].setfoot
                                                                                    });
                                                                                    setDialogOpen(true);
                                                                                }}>
                                                                                <CardContent>
                                                                                    <Typography variant="body1" component="p">
                                                                                        {dateRow.detail[index].name}
                                                                                    </Typography>
                                                                                </CardContent>
                                                                            </Card>
                                                                        ) : index < dateRow.total ? (
                                                                            <Card style={{
                                                                                borderRadius: 10, backgroundColor: '#ABEBC6', height: '50px', width: '100%',
                                                                            }}>
                                                                                <CardContent>
                                                                                    <Typography variant="body1" component="p">
                                                                                        ว่าง
                                                                                    </Typography>
                                                                                </CardContent>
                                                                            </Card>
                                                                        ) : ('')}
                                                                    </TableCell>
                                                                ))}
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            );
                                        })}
                                    </>
                                );
                            })}
                        </TableContainer>

                    </Col>

                </Row>
                <Dialog
                    fullWidth
                    maxWidth="xs"
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle style={{ cursor: 'move', fontSize: 26 }} id="alert-dialog-title">รายละเอียด</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography display="inline" variant="h6" >ชื่อลูกค้า: {dialogContent?.name} </Typography> <br></br>
                            <Typography display="inline" variant="h6" >จำนวน: {dialogContent?.count} คน</Typography> <br></br>
                            <Typography display="inline" variant="h6" >หมายเหตุ: {dialogContent?.note ? dialogContent?.note : '-'} </Typography> <br></br>
                            <Typography display="inline" variant="h6" >เซ็ตอาหาร: {dialogContent?.setfoot ? dialogContent?.setfoot : '-'} </Typography> <br></br>
                            <Typography display="inline" variant="h6" >สถานะ: {dialogContent?.status} </Typography> <br></br>

                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>

                        <Button variant="contained" size="large" onClick={() => setDialogOpen(false)} >
                            ตกลง
                        </Button>

                    </DialogActions>
                </Dialog>


            </>

        </>
    );
};

export default function IntegrationNotistack() {
    return (
        <SnackbarProvider maxSnack={3}>
            <Booking />
        </SnackbarProvider>
    );
}
