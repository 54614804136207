import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import Main from "apps/shared/components/main";
const GuardRoutes = ({ component: Component, ...rest }) => {
  if (localStorage.getItem('position_manage_position_permissions') == 'true') {
    return (
      <Main>
        <Outlet {...rest} />
      </Main>
    );
  } else {
    return <Navigate to="/login" replace={true} />;
  }
};

export default GuardRoutes;
