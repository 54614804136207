import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import PrintableComponent from "./PrintableComponent";
import { Button } from "@mui/material";

function YourComponent() {
  const componentRef = useRef();

  const handlePrint = () => {
    window.print();
  };

  return (
    <div>
      <head>
        <style>
          {`
            @media print {
              .print-hide {
                display: none !important;
              }
              
              .page-break {
                page-break-before: always;
              }
            }
          `}
        </style>
      </head>
      <Button
        className="print-hide"
        variant="contained"
        style={{ backgroundColor: "#FFCB42", color: "#000000" }}
        onClick={handlePrint}
      >
        Print to PDF
      </Button>
      <div>
        <div className="page-break" />
        {/* Add a page break before the table */}
        <PrintableComponent ref={componentRef} />
      </div>
    </div>
  );
}

export default YourComponent;
