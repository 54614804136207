import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import CloseIcon from "@mui/icons-material/Close";
import { TablePagination } from '@mui/material';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import LoupeIcon from '@mui/icons-material/Loupe';
import SendIcon from '@mui/icons-material/Send';
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  TextField,
  Box,
  Stack,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,

} from "@mui/material";
import IconButton from '@mui/material/IconButton';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import * as XLSX from 'xlsx';


const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 2em;
`;

const ManageReserve = () => {
  document.title = 'แจ้งเตือนการจอง - ระบบการจองคิวล่วงหน้า (Advance booking system)';
  const navigate = useNavigate();

  const [reserves, setReserves] = useState([]);
  const [foots, setFoots] = useState([]);

  const [loading, setLoading] = useState(true);
  const [position, setPosition] = useState([]);
  let [searchPosition, setsearchPosition] = useState('%');
  const [isLoading, setIsLoading] = useState(true);
  const [positiongname, setpositiongname] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const rowsCount = reserves.length;
  const [dialogOpen, setDialogOpen] = useState(false);

  const [dialogContent, setDialogContent] = useState(null);

  const [open, setOpen] = useState(false);


  const handleClickOpen = (id) => {
    const token = localStorage.getItem("SavedToken");
    Swal.fire({
      title: 'Loading...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    axios
      .get(process.env.REACT_APP_BASE_API_URL + `/api/reportkitchen/id/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setOpen(true);
        Swal.close();


        setFoots(res.data);
      });

  };

  const handleClickOpenall = (e)  => {
    if(!formatDate(values.date) || formatDate(values.date) != '1970-01-01'){
      const data = {
        "res_id": values.searchReserveid,
        "date": formatDate(values.date),
        "res_name": values.searchname
      };
  
      console.log(data)
      Swal.fire({
        title: 'Loading...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading()
        }
      });
      const token = localStorage.getItem('SavedToken');
  
      axios
        .post(process.env.REACT_APP_BASE_API_URL + "/api/reportkitchen/all/", data, {
          headers: {
            Authorization: `Bearer ${token}`
          }
  
        })
        .then((res) => {
          console.log(res.data)
          setOpen(true);
          Swal.close();
          setFoots(res.data);
        }).catch((error) => {
          Swal.close();
          console.error("Error making Axios request:", error);
          // แสดง error message ถ้าต้องการ
          Swal.fire({
            title: 'Error',
            text: 'There was an error processing your request.',
            icon: 'error'
          });
        });
        
    }else{
      Swal.fire({
        title: 'Error',
        text: 'ข้อมูลมากเกินไป กรุณาระบุวันที่',
        icon: 'error'
      });
    }
    

  };
  const handleClose = () => {
    setOpen(false);
  };




  useEffect(() => {
    const token = localStorage.getItem("SavedToken");
    Swal.fire({
      title: 'Loading...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });
    axios
      .get(process.env.REACT_APP_BASE_API_URL + "/api/reportkitchen", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setReserves(res.data);
        setLoading(false);
      });
    Swal.close();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleEdit = (reserve) => {
    const token = localStorage.getItem('SavedToken');
    Swal.fire('Error!', 'ยังไม่ได้ทำครับ', 'error');

  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: 'Loading...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
            Swal.showLoading()
          }
        });
        const token = localStorage.getItem("SavedToken");
        axios
          .delete(process.env.REACT_APP_BASE_API_URL + `/manage/reserve/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setReserves(reserves.filter((reserve) => reserve.reserve_id !== id));
            Swal.fire("Deleted!", "The reserve has been deleted.", "success");
          }).catch(err => {
            console.log(err);
            Swal.fire('Error!', err.response.data.detail, 'error');
          });
      }
    });
  };



  const [values, setValues] = useState({ date: null, searchReserveid: null, searchname: null });

  const handleChangeData = (prop) => (event) => {
    if (prop === 'paid') {
      setValues({ ...values, [prop]: event.target.checked });

    } else if (prop === 'count_cus') {
      let value = event.target.value;
      let deposit = value * 300;
      setValues({ ...values, 'count_cus': value, 'deposit': deposit });
    } else if (prop === 'date') {
      let value = event;
      setValues({ ...values, [prop]: value });
    } else if (prop === 'dateend') {
      let value = event;
      setValues({ ...values, [prop]: value });
    } else {
      let value = event.target.value;
      setValues({ ...values, [prop]: value });
    }
  };
  const handleSubmit = (e) => {
    try {
      const data = {
        "res_id": values.searchReserveid,
        "date": formatDate(values.date),
        "res_name": values.searchname
      };

      console.log(data)
      Swal.fire({
        title: 'Loading...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading()
        }
      });
      const token = localStorage.getItem('SavedToken');

      axios
        .post(process.env.REACT_APP_BASE_API_URL + "/api/reportkitchen/", data, {
          headers: {
            Authorization: `Bearer ${token}`
          }

        })
        .then((response) => {
          console.log("response booking", response);
          if (response.data.message === "Success") {
            console.log(response.data.detail)
            setReserves(response.data.detail);
            Swal.close()
          } else {
            Swal.close()
          }
        });
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response && error.response.data && error.response.data.detail
          ? error.response.data.detail
          : "ไม่สามารถติดต่อ server ได้",
      });
    }

  };
  const handlePositionChange = (event) => {
    setsearchPosition(event.target.value);
  };

  const formatDate = (date) => {
    const newDate = new Date(date);
    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, '0');
    const day = String(newDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  let res_id = '';
    let res_name = '';
  const generateExcelContent = (foots) => {
    const wb = XLSX.utils.book_new();
    const ws_data = [];
  
    foots.forEach(row => {
      res_id = row.reserve_id;
      res_name = row.reserve_customer_name;
      ws_data.push(['', `      ${formatDate(row.reserve_date)}`]);
      ws_data.push(['', `    ${row.reserve_id} ${row.reserve_customer_name}`]);
      ws_data.push(['', `    ${row.reserve_footsetlst.name} (${row.reserve_footsetlst.price}) ${row.reserve_footset_cnt} ชุด`]);

      row.reserve_footsetlst.foodSetDetail.forEach((food, index) => {
        let orderNumber = `${index + 1}.`;
        let foodName = food.name;
        let quantity = `x${row.reserve_footset_cnt}`;

        ws_data.push([orderNumber, foodName, quantity]);
      });

      ws_data.push(['==================================']);
    });

    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    ws['!cols'] = [
      { wch: 5 }, // Width for column 1
      { wch: 30 }, // Width for column 2
      { wch: 10 }  // Width for column 3
    ];
    XLSX.utils.book_append_sheet(wb, ws, `Food Orders`);

    return wb;
  };

  const saveToExcel = (foots) => {
    const wb = generateExcelContent(foots);
    let filename = '' 
    if(!formatDate(values.date) || formatDate(values.date) != '1970-01-01'){
      filename = `foodOrders_${formatDate(values.date)}.xlsx`
    
    }else{
      filename = `foodOrders_${res_id}_${res_name}.xlsx`

    }
    XLSX.writeFile(wb, filename);
  };

  // Adjust your handleSaveFile function:
  const handleSaveFile = () => {
    saveToExcel(foots);
  };


  return (
    <>
      <HeaderContainer>
        <Typography variant="h5" fontWeight="700">
          รายการการจองคิวร้านอาหาร (สำหรับห้องครัว)
        </Typography>

      </HeaderContainer>

      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>


        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <Typography variant="h5" fontWeight="700" gutterBottom>
            รายการอาหาร
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {foots.length > 0 ?
            foots.map((row) => (
              <div key={row.reserve_id}>
                <Typography variant="h6" style={{ textAlign: 'center' }} gutterBottom>
                {formatDate(row.reserve_date)}
                </Typography>
                <Typography variant="h6" style={{ textAlign: 'center' }} gutterBottom>
                  {row.reserve_id} {row.reserve_customer_name}
                </Typography>
                <Typography variant="h6" style={{ textAlign: 'center' }} gutterBottom>
                  {row.reserve_footsetlst.name} ({row.reserve_footsetlst.price}) {row.reserve_footset_cnt} ชุด
                </Typography>
                <Typography variant="h6">
                  {row.reserve_footsetlst.foodSetDetail.map((food, index) => (
                    <Row>
                      <Col xs={4} md={1}>
                        {index + 1}.
                      </Col>
                      <Col xs={4} md={9}>
                        {food.name}
                      </Col>
                      <Col xs={4} md={2}>
                        x{row.reserve_footset_cnt}
                      </Col>
                    </Row>
                  ))}
                </Typography>
                <Typography style={{ textAlign: 'center' }} variant="h6">
                  ========================================
                </Typography>
              </div>
            ))
            : <Typography variant="h6" gutterBottom>
              ไม่พบรายการอาหาร
            </Typography>}
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={() => handleSaveFile(foots)}
          >
            Save File
          </Button>
          <Button autoFocus onClick={handleClose}>
            ปิด
          </Button>
        </DialogActions>
      </Dialog>

      <Paper sx={{ padding: "1rem", marginTop: "-1.5rem" }}>
        <Typography fontWeight="700" variant="h6">ค้นหาระบุ</Typography>

        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs={4} md={2}>
              <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                <TextField
                  id="searchReserveid"
                  label="เลขที่การจอง"
                  variant="outlined"
                  onChange={handleChangeData("searchReserveid")}
                  fullWidth
                />
              </FormControl>
            </Col>
            <Col xs={4} md={2}>
              <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                <TextField
                  id="searchname"
                  label="ชื่อลูกค้า"
                  variant="outlined"
                  onChange={handleChangeData("searchname")}

                  fullWidth
                />
              </FormControl>
            </Col>
            <Col xs={4} md={2}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="วันที่จอง"
                    value={values.date} // access the date value from the state
                    onChange={handleChangeData('date')} // update the date value in the state
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Col>
            <Col xs={4} md={2}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <Button variant="contained" size="large" onClick={handleSubmit}>
                  ค้นหา
                </Button>
              </FormControl>
            </Col>

            <Col xs={4} md={1}></Col>
            <Col xs={4} md={3}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <Button variant="contained" style={{ backgroundColor: "#FFCB42", color: "#000000" }} size="large" endIcon={<SendIcon />}
                  onClick={() => handleClickOpenall()}
                >
                  Export รายการที่ค้นหาทั้งหมด
                </Button>
              </FormControl>
            </Col>

          </Row>


        </form>
      </Paper>
      <br></br>
      <Typography display="inline" variant="body2" style={{ color: "#5872E9" }} > <CheckCircleIcon style={{ color: "#36AE7C" }} /> รายการจองคิวสำเร็จ {rowsCount} รายการ  </Typography>
      <Typography display="inline" variant="body2" style={{ color: "#AAAAAA" }} > | </Typography>
      <Typography display="inline" variant="body2" style={{ color: "#5872E9" }} > <CancelIcon style={{ color: "#EB5353" }} /> รายการจองคิวไม่สำเร็จ 0 รายการ  </Typography>
      <br></br><br></br>

      {loading ? (
        <p>Loading...</p>
      ) : (

        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: '#F5F5F5' }}>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", fontSize: 18 }} >เลขที่การจอง</TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 18 }}>ชื่อลูกค้า</TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 18 }}>เบอร์โทร</TableCell>

                <TableCell sx={{ fontWeight: "bold", fontSize: 18 }}>จำนวนคน</TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 18 }}>วันที่จอง</TableCell>

                <TableCell sx={{ fontWeight: "bold", fontSize: 18 }}>หมายเหตุ</TableCell>

                <TableCell sx={{ fontWeight: "bold", fontSize: 18 }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reserves.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <TableRow key={row.reserve_id}>
                  <TableCell sx={{ fontSize: 16 }} component="th" scope="row">{row.reserve_id}</TableCell>
                  <TableCell sx={{ fontSize: 16 }}>{row.reserve_customer_name}</TableCell>
                  <TableCell sx={{ fontSize: 16 }}>{row.reserve_customer_tel}</TableCell>

                  <TableCell sx={{ fontSize: 16 }}>{row.reserve_count} คน</TableCell>
                  <TableCell sx={{ fontSize: 16 }}>{formatDate(row.reserve_date)}</TableCell>

                  <TableCell sx={{ fontSize: 16 }}>{row.reserve_note ? row.reserve_note : "-"}</TableCell>

                  <TableCell sx={{ fontSize: 16 }}>

                    <Button variant="contained" style={{ backgroundColor: "#FFCB42", color: "#000000", marginTop: "0.2rem", marginBottom: "0.2rem" }}
                      onClick={() => handleClickOpen(row.reserve_id)}
                    >
                      <LoupeIcon color="primary" /> รายการอาหาร
                    </Button>

                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <br></br>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={rowsCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>


      )}
      {/* <Dialog
        fullWidth
        maxWidth="md"
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{ cursor: 'move', fontSize: 26 }} id="alert-dialog-title">รายละเอียด</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography display="inline" variant="h6" >ชื่อลูกค้า: {dialogContent?.name} </Typography> <br></br>
            <Typography display="inline" variant="h6" >หมายเหตุ: {dialogContent?.note ? dialogContent?.note : '-'} </Typography> <br></br>
            <Typography display="inline" variant="h6" >เซ็ตอาหาร: {dialogContent?.setfoot ? dialogContent?.setfoot : '-'} </Typography> <br></br>
            <Typography display="inline" variant="h6" >สถานะ: {dialogContent?.status} </Typography> <br></br>

          </DialogContentText>

        </DialogContent>
        <DialogActions>

          <Button variant="contained" size="large" onClick={() => setDialogOpen(false)} >
            ตกลง
          </Button>

        </DialogActions>
      </Dialog> */}
    </>
  );
};

export default ManageReserve;
