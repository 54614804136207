import { styled } from "@mui/material/styles";

const Content = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, width, size, breakwidth, drawerwidth }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  margin: 10,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: size.width <= breakwidth ? "10px" : `-${width - 10}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: size.width <= breakwidth ? "10px" : drawerwidth,
  }),
}));

export default Content;

