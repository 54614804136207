import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Stack,
  IconButton,
  Drawer,
  Box,
  List,
  ListItem,
  Divider,
  Badge,
} from "@mui/material";
import { useState } from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CloseIcon from "@mui/icons-material/Close";

import ListNotification from "./ListNotification";

export default function Notification() {
  const [isNotificationOpen, setisNotificationOpen] = useState(false);

  return (
    //   <div>Notification</div>;
    <>
      <IconButton
        sx={{ color: "#272d43" }}
        size="large"
        onClick={() => setisNotificationOpen(true)}
      >
        <Badge badgeContent={3} color="error">
          <NotificationsIcon sx={{ fontSize: 40 }} />
        </Badge>
      </IconButton>
      <Drawer
        anchor="right"
        open={isNotificationOpen}
        onClose={() => setisNotificationOpen(false)}
        sx={{}}
      >
        <Box
          sx={{
            width: "30vw",
            textAlign: "center",
            // display: "flex",
            // flexDirection: "row",
            // alignItems: "center",
            // justifyContent: "center",
          }}
          p={2}
          role="presentation"
        >
          <Stack
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton
              sx={{ width: "15%", color: "#5872e9" }}
              size="large"
              color="warning"
              paddingRight="10px"
              onClick={() => setisNotificationOpen(false)}
            >
              <CloseIcon fontSize="large" sx={{}} />
            </IconButton>
            <Typography variant="h5" component="div" sx={{ width: "90%" }}>
              รายการแจ้งเตือน
            </Typography>
          </Stack>
          <Divider />
          <ListNotification />
        </Box>
      </Drawer>
    </>
  );
}
