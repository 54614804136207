import react from "react";
import { Stack, Divider, IconButton, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from 'react-router-dom';


class Header extends react.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Stack
        sx={{
          marginTop: 1,
          marginLeft: 1,
          marginRight: 1,
        }}
        spacing={2}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <div>
            <IconButton aria-label="delete" size="large" onClick={this.props.slideBarOnClick}>
              <MenuIcon
                sx={{
                  color: "#272D43",
                }}
              />
            </IconButton>
          </div>
        
          <Link to="/dashboard" style={{ textDecoration: 'none' }}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "#272D43" }}
              >
                Ad
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "#FED523" }}
              >
                Booking
              </Typography>
            </Stack>
            </Link>
       
        </Stack>
        <Divider />
      </Stack>
    );
  }
}

export default Header;
