import React from 'react';
import bg_login from "../../assets/image/bg.png";
import ellipse from "../../assets/image/ellipse_1.svg";
import Styles from "./index.module.css";
import {
    Button,
    Grid,
    Typography,
    Stack,
    FormControl,
    TextField,
    InputLabel,
    InputAdornment,
    IconButton,
    OutlinedInput,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import Swal from "sweetalert2";

class Dashboard extends React.Component {
    render() {
        document.title = 'ระบบการจองคิวล่วงหน้า (Advance booking system)';

        return (
            <div>
                <Grid container  sx={{ marginTop: "45vh" }} style={{ zoom: 1}}>

                    <Grid item xs={12} backgroundColor="#E7EBF7">
                        <Stack

                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ height: "100%" }}
                        >
                            <img className={Styles.puff_out_center} src={ellipse} alt="ellipse" />
                            <img className={Styles.bg} src={bg_login} alt="bg" />
                            <div className={Styles.bg_text}>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <Typography
                                        variant="h4"
                                        component="h4"
                                        display="flex"
                                        flexDirection="row"
                                        fontWeight="700"
                                    >
                                        Ad
                                        {process.env.API_URL}
                                    </Typography>
                                    <Typography
                                        variant="h4"
                                        component="h4"
                                        // marginLeft="5%"
                                        color="#FFCB42"
                                        fontWeight="700"
                                    >
                                        Booking
                                    </Typography>
                                </Stack>

                                <Typography
                                    variant="body2"
                                    fontWeight="700"
                                    sx={{ marginBottom: "3rem" }}
                                >
                                    ระบบการจองคิวล่วงหน้า
                                     (Advance booking system)
                                </Typography>
                            </div>
                        </Stack>
                    </Grid>
                </Grid>

            </div>
        );
    }
}

export default Dashboard;