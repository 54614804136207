import { Toolbar } from "@mui/material";
import React from "react";
import Navbar from "./SubMain/navbar";
import Sidebar from "./SubMain/sidebar";
import Content from "./SubMain/content";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const drawerWidth = 275;
const breakWidth = 1000;
const getSize = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
};

class MainComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      forcedOpen: false

    }
    this.slideBarOnClick = this.slideBarOnClick.bind(this);
    this.handleScreen = this.handleScreen.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props || prevState.forcedOpen !== this.state.forcedOpen) {
      this.handleScreen();
    }
  }

  handleScreen = () => {
    if (this.props.size.width < breakWidth) {
      if (!this.state.forcedOpen) {
        this.setState({ open: false });
      }
    } else {
      this.setState({ open: true, forcedOpen: false });
    }
  }

  slideBarOnClick = () => {
    console.log('slideBarOnClick called');

    this.setState({
      open: !this.state.open,
      forcedOpen: !this.state.open
    });
  }

  render() {
      console.log('MainComponent render, state:', this.state);

    return (
      <div style={{ position: 'relative' , zIndex: 1000}}>
        <Navbar  {...this.props} open={this.state.open} slideBarOnClick={this.slideBarOnClick} breakwidth={breakWidth}/>
        <Content  {...this.props} open={this.state.open} drawerwidth={drawerWidth}>
          <Toolbar />
          {this.props.children}
        </Content>
     
        <Sidebar {...this.props} open={this.state.open} slideBarOnClick={this.slideBarOnClick} breakwidth={breakWidth} />

      </div>
    );
  }
}

const Main = (props) => {
  const [size, setSize] = React.useState(getSize());
  const handleResize = React.useCallback(() => {
    let ticking = false;
    if (!ticking) {
      window.requestAnimationFrame(() => {
        setSize(getSize());
        ticking = false;
      });
      ticking = true;
    }
  }, []);

  React.useEffect(() => {
    console.log(size)
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [size]);
  

  return <MainComponent  {...props} size={size} drawerwidth={drawerWidth} breakwidth={breakWidth} />;
};

export default Main;
