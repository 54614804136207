import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import bg_login from "../../assets/image/bg.png";
import ellipse from "../../assets/image/ellipse_1.svg";
import Styles from "./index.module.css";
import querystring from "querystring";
import {
  Button,
  Grid,
  Typography,
  Stack,
  FormControl,
  TextField,
  InputLabel,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import Swal from "sweetalert2";

export default function Login() {
  document.title = 'Login - ระบบการจองคิวล่วงหน้า (Advance booking system)';

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);


  const [values, setValues] = useState({
    username: "",
    password: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const submitLogin = async (e) => {
    e.preventDefault();
    // console.log(values)

    try {
      const data = {
        emp_username: values["username"],
        emp_password: values["password"],
      };
      Swal.fire({
        title: 'Loading...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading()
        }
      });

      await axios
        .post(process.env.REACT_APP_BASE_API_URL + "/api/auth/",data,{
         
        })
        .then((response) => {
          console.log("response login", response);
          if (response.data.message === "Success") {
            
            localStorage.setItem("user",response.data.detail[0].user);
            localStorage.setItem("fname",response.data.detail[0].fname);
            localStorage.setItem("lname",response.data.detail[0].lname);
            localStorage.setItem("SavedToken", response.data.detail[0].token);

          
            
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Login success",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/dashboard");
              }
            });
          }else{
            Swal.fire({
              icon: "error",
              title: "",
              text: response.data.detail,
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/login");
              }
            });
          }
        });
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response && error.response.data && error.response.data.detail 
        ? error.response.data.detail  
        : error,      
      });
    }
    //window.location.href = 'http://localhost:3000/dashboard'
    //navigate("/dashboard");
  };

  return (
    <Grid container sx={{ height: "100vh" }}  style={{zoom:1.25}}>
      <Grid item xs={12} md={8} backgroundColor="#E7EBF7">
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <img className={Styles.puff_out_center} src={ellipse} alt="ellipse" />
          <img className={Styles.bg} src={bg_login} alt="bg" />
          <div className={Styles.bg_text}>
            

          </div>
        </Stack>
      </Grid>
      <Grid item  xs={12} md={4} >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography
              variant="h4"
              component="h4"
              display="flex"
              flexDirection="row"
              fontWeight="700"
            >
              Ad
            </Typography>
            <Typography
              variant="h4"
              component="h4"
              // marginLeft="5%"
              color="#FFCB42"
              fontWeight="700"
            >
              Booking
            </Typography>
          </Stack>

          <Typography
            variant="body2"
            fontWeight="700"
            sx={{ marginBottom: "3rem" }}
          >
            ระบบการจองคิวล่วงหน้า  (Advance booking system)

          </Typography>
        
          
          <form onSubmit={submitLogin}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                variant="subtitle1"
                fontWeight="700"
                sx={{ marginBottom: "1rem" }}
              >
                เข้าสู่ระบบ
              </Typography>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <TextField
                  id="username"
                  label="Username"
                  variant="outlined"
                  onChange={handleChange("username")}
                  required
                  fullWidth
                />
              </FormControl>
              <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password *
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={values.showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange("password")}
                  required
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
            </Stack>
           
            <Button
              variant="contained"
              style={{ backgroundColor: "#FFCB42", color: "#000000" }}
              type="submit"
              fullWidth
            >
              เข้าสู่ระบบ
            </Button>
          </form>
        </Stack>
      </Grid>
    </Grid>
  );
}
