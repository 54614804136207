import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import CloseIcon from "@mui/icons-material/Close";
import { TablePagination } from '@mui/material';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import LoupeIcon from '@mui/icons-material/Loupe';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';


import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Divider,
  TextField,
  Box,
  Stack,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,

} from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import styled from "styled-components";

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 2em;
`;

const ManagePatient = () => {

  const [group, setGroup] = useState([]);
  const [loading, setLoading] = useState(true);
  const [patient, setPatient] = useState([]);
  let [searchSex, setsearchSex] = useState('0');
  const [isLoading, setIsLoading] = useState(true);
  const [positiongname, setpositiongname] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const rowsCount = group.length;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogOpenInsert, setDialogOpenInsert] = useState(false);
  const [selectedStartTime, setSelectedStartTime] = useState();
  const [selectedEndTime, setSelectedEndTime] = useState();
  const [editRowIndex, setEditRowIndex] = useState(null);

  const [selectedStartTimeInsert, setSelectedStartTimeInsert] = useState();
  const [selectedEndTimeInsert, setSelectedEndTimeInsert] = useState();

  const [timeSlots, setTimeSlots] = useState([{ startTime: '00:00 00', endTime: '00:00 00' }]);

  const [around, setAround] = useState([]);
  const [zone, setZone] = useState([]);

  const [columns, setColumns] = useState(null);
  const [rows, setRows] = useState(null);

  const [isEditable, setIsEditable] = useState(false);
  const [editableData, setEditableData] = useState(null);


  const handleAddTimeSlot = () => {
    setTimeSlots([...timeSlots, { startTime: '00:00 00', endTime: '00:00 00' }]);
  };

  const handleTimeChange = (index, type, newValue) => {
    const newTimeSlots = [...timeSlots];
    newTimeSlots[index][type] = newValue;
    setTimeSlots(newTimeSlots);
  };

  const handleEdit = (index) => {
    setEditRowIndex(index);
  };

  const handleCancel = () => {
    setEditRowIndex(null);
  };

  const handleSave = () => {
    // here you would update your data

    setEditRowIndex(null);
  };



  useEffect(() => {
    const token = localStorage.getItem("SavedToken");
    axios
      .get(process.env.REACT_APP_BASE_API_URL + "/api/group/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setGroup(res.data);
        setLoading(false);
      });

  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAdd = () => {
    const token = localStorage.getItem("SavedToken");

  };

  const handleEditMain = (user) => {
    const token = localStorage.getItem('SavedToken');

  };

  const handleDelete = (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: 'Loading...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
            Swal.showLoading()
          }
        });
        const token = localStorage.getItem("SavedToken");
        axios
          .delete(process.env.REACT_APP_BASE_API_URL + `/api/group/${formatDate(item.around_date)}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            axios
            .get(process.env.REACT_APP_BASE_API_URL + "/api/group/", {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              setGroup(res.data);
              setLoading(false);
            });
            Swal.fire("Deleted!", "The user has been deleted.", "success");
          }).catch(err => {
            console.log(err);
            Swal.fire('Error!', err.response.data.detail, 'error');
          });
      }
    });
  };


  const [values, setValues] = useState({
    datestart: null,
    dateend: null,
    datestartinsert: null,
    dateendinsert: null,
    count_cus: 0,
    deposit: 0,
    paid: false,
    zone: "%"
  });

  const handleChangeData = (prop) => (event) => {
    if (prop === 'paid') {
      setValues({ ...values, [prop]: event.target.checked });
    } else if (prop === 'count_cus') {
      let value = event.target.value;
      let deposit = value * 300;
      setValues({ ...values, 'count_cus': value, 'deposit': deposit });
    } else if (prop === 'datestart' || prop === 'dateend' || prop === 'datestartinsert' || prop === 'dateendinsert') {
      let value = event;
      setValues({ ...values, [prop]: value });
    } else {
      let value = event.target.value;

      setValues({ ...values, [prop]: value });
    }


  };

  const handleSubmit = (e) => {
    e.preventDefault();
    Swal.fire({
      title: 'Loading...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    let datestart = '' 
    let dateend = '' 
    if (!values.datestart) {
      datestart = "2000-01-01";
    }else{
      datestart = formatDate(values.datestart)
    }
    if (!values.dateend) {
      dateend = "2999-01-01";
    }else{
      dateend = formatDate(values.dateend)
    }

    const token = localStorage.getItem("SavedToken");
    axios.get(process.env.REACT_APP_BASE_API_URL + `/api/group/${datestart}/${dateend}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => {
        console.log(res.data);
    
        setGroup(res.data);
        Swal.close();
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        Swal.fire('Error!', err.response.data.detail, 'error');
      });
  };
  const handleSexChange = (event) => {
    setsearchSex(event.target.value);
  };

  const handleOpenInsert = (event) => {
    setDialogOpenInsert(true)

    const token = localStorage.getItem("SavedToken");
    axios
      .get(process.env.REACT_APP_BASE_API_URL + "/api/zone/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setZone(res.data);
        console.log(res.data)

      });
  }

  const handleOpen = (event) => {
    setDialogOpen(true)

    const token = localStorage.getItem("SavedToken");
    axios
      .get(process.env.REACT_APP_BASE_API_URL + "/api/group/" + formatDate(event.around_date), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setAround(res.data);
        console.log(res.data)
        setColumns([...new Set(res.data?.map(item => item.zone_name))]);
        setRows([...new Set(res.data?.map(item => item.around_starttime + ' - ' + item.around_endtime))]);

      });
  }


  const handleStartEditing = () => {
    setIsEditable(true);
    setEditableData(around);
  }

  const handleCancelEditing = () => {
    setIsEditable(false);
    setEditableData(null);
  }

  const handleSaveEditing = () => {
    console.log(editableData)
    Swal.fire({
      title: 'Loading...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });
    const token = localStorage.getItem('SavedToken');
        axios.put(process.env.REACT_APP_BASE_API_URL + `/api/group`, editableData, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
          .then(res => {
            axios
            .get(process.env.REACT_APP_BASE_API_URL + "/api/group/", {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              setGroup(res.data);
              Swal.fire('Saved!', 'The group has been edited.', 'success');

            }).catch(err => {
              console.log(err)
              Swal.fire('Error!', err.response.data.detail, 'error');
            })
            
          })
          .catch(err => {
            console.log(err)
            Swal.fire('Error!', err.response.data.detail, 'error');
          })
    setDialogOpen(false)
    setIsEditable(false);
    setEditableData(null);
  }
  const [inputValues, setInputValues] = useState({});


  const handleChangeInsert = (zoneId) => (event) => {
    setInputValues({
      ...inputValues,
      [zoneId]: event.target.value,
    });
  };

  const handleSaveInsert = () => {

    
    let hasNegativeOrEmptyValues = false;
    if (Object.keys(inputValues).length === 0) {
      hasNegativeOrEmptyValues = true;
    } else {
      for (let key in inputValues) {
        if (inputValues[key] < 0 || inputValues[key] === '') {
          hasNegativeOrEmptyValues = true;
          break;
        }
      }
    }


    if (hasNegativeOrEmptyValues) {
      Swal.fire('Error!', 'ค่าไม่สามารถเป็นลบหรือว่าง', 'error');
    } else {
      Swal.fire({
        title: 'Loading...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading()
        }
      });
      const data = {
        "dateStart": formatDate(values.datestartinsert),
        "dateEnd": formatDate(values.dateendinsert),
        "zone": inputValues
      }
      console.log(data)
      const token = localStorage.getItem("SavedToken");
      axios
        .post(process.env.REACT_APP_BASE_API_URL + "/api/group/", data, {
          headers: {
            Authorization: `Bearer ${token}`
          }

        })
        .then((response) => {
          console.log("response booking", response);
          if (response.data.message === "Success") {
            Swal.fire({
              icon: "success",
              title: "Updated success",
              text: response.data.message,
            });
            axios
              .get(process.env.REACT_APP_BASE_API_URL + "/api/group/", {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((res) => {
                setGroup(res.data);
                setLoading(false);
              });

          } else {
            Swal.fire({
              icon: "error",
              title: "",
              text: response.data.detail
            });
          }
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response && error.response.data && error.response.data.detail
              ? error.response.data.detail
              : "ไม่สามารถติดต่อ server ได้",
          });
        });
      
     
      setDialogOpenInsert(false)
      setInputValues({});
    }

  }

  const handleChange = (e, id) => {
    setEditableData(prevData => prevData.map(item => item.group_id === id ? { ...item, group_total: e.target.value } : item))
  }

  const formatDate = (date) => {
    const newDate = new Date(date);
    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, '0');
    const day = String(newDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }


  return (
    <>
      <HeaderContainer>
        <Typography variant="h5" fontWeight="700">
          รายการการจองต่อว้น
        </Typography>
        <Button
          variant="contained"
          style={{ backgroundColor: "#59CE8F" }}
          sx={{ marginLeft: "1rem" }}
          onClick={() => handleOpenInsert()}
        >
          <AddCircleIcon sx={{ marginRight: ".5rem" }} />
          เพิ่มการจองต่อวัน
        </Button>
      </HeaderContainer>

      <Paper sx={{ padding: "1rem", marginTop: "-1.5rem" }}>
        <Typography fontWeight="700" variant="h6">ค้นหาระบุ</Typography>

        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs={6} md={3}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="วันที่เริ่ม"
                    value={values.datestart} // access the date value from the state
                    onChange={handleChangeData('datestart')} // update the date value in the state
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>

            </Col>
            <Col xs={6} md={3}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="วันสิ้นสุด"
                    value={values.dateend} // access the date value from the state
                    onChange={handleChangeData('dateend')} // update the date value in the state
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>

            </Col>
            <Col xs={6} md={4}></Col>
            <Col xs={4} md={2}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <Button variant="contained" size="large" onClick={handleSubmit}>
                  ค้นหา
                </Button>
              </FormControl>
            </Col>

          </Row>


        </form>
      </Paper>
      <br></br>
      <Typography display="inline" variant="body2" style={{ color: "#5872E9" }} > <CheckCircleIcon style={{ color: "#36AE7C" }} /> รายการพนักงานสำเร็จ {rowsCount} รายการ  </Typography>
      <Typography display="inline" variant="body2" style={{ color: "#AAAAAA" }} > | </Typography>
      <Typography display="inline" variant="body2" style={{ color: "#5872E9" }} > <CancelIcon style={{ color: "#EB5353" }} /> รายการพนักงานไม่สำเร็จ 0 รายการ  </Typography>
      <br></br><br></br>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: '#F5F5F5' }}>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", fontSize: 18 }}>วันที่</TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 18 }}>รอบต่อวันทั้งหมด</TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 18 }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {group.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <TableRow key={row.patient_id}>
                  <TableCell sx={{ fontSize: 16 }} component="th" scope="row">{formatDate(row.around_date)}</TableCell>
                  <TableCell sx={{ fontSize: 16 }}>{row.total} รอบ</TableCell>
                  <TableCell>

                    <Button variant="contained" style={{ backgroundColor: "#FFCB42", color: "#000000", marginLeft: '1rem' }} onClick={() => handleOpen(row)}>
                      <LoupeIcon color="primary" /> รายละเอียด
                    </Button>

                    <Button variant="contained" style={{ backgroundColor: "#DC143C", color: "#fff", marginLeft: '1rem' }} onClick={() => handleDelete(row)}>
                      <CloseIcon /> Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <br></br>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={rowsCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>




      )}
      <Dialog
        fullWidth
        maxWidth="xl"
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{ fontSize: 26 }} id="alert-dialog-title">รายละเอียด
          <Button sx={{ marginLeft: "1rem" }} variant="contained" size="large" onClick={handleStartEditing}>
            แก้ไข
          </Button>
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontSize: 16 }}>Times</TableCell>
                    {columns?.map((zoneName, index) => (
                      <TableCell align="center" sx={{ fontSize: 16 }} key={index}>{zoneName}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.map((time, rowIndex) => (
                    <TableRow key={rowIndex}>
                      <TableCell sx={{ fontSize: 16 }} component="th" scope="row">
                        {time}
                      </TableCell>
                      {columns?.map((zoneName, columnIndex) => {
                        const data = isEditable ? editableData : around;
                        const item = data.find(
                          i => i.zone_name === zoneName && (i.around_starttime + ' - ' + i.around_endtime) === time
                        );
                        return (
                          <TableCell align="center" key={columnIndex}>
                            {isEditable ? (
                              <TextField
                                hiddenLabel
                                size="small"
                                variant="filled"
                                type="number"
                                InputProps={{
                                  readOnly: false,
                                }}
                                inputProps={{
                                  style: { textAlign: 'center' } // Center the text
                                }}
                                required
                                fullWidth
                                value={item?.group_total}
                                onChange={(e) => handleChange(e, item?.group_id)}
                              />
                            ) : (
                              item?.group_total
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {isEditable ? (
            <>
              <Button variant="contained" size="large" color="success" onClick={handleSaveEditing}>
                บันทึก
              </Button>
              <Button variant="contained" color="error" size="large" onClick={handleCancelEditing}>
                ยกเลิก
              </Button>
            </>
          ) : (

            <Button variant="contained" size="large" onClick={() => setDialogOpen(false)}>
              ตกลง
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="xl"
        open={dialogOpenInsert}
        onClose={() => setDialogOpenInsert(false)}
        aria-labelledby="alert-dialog-title-insert"
        aria-describedby="alert-dialog-description-insert"
      >
        <DialogTitle style={{ cursor: 'move', fontSize: 26 }} id="alert-dialog-title-insert">เพิ่มการจอง</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-insert">
            <Row>
              <Col xs={6} md={2}>
                <Typography sx={{ marginTop: "0.7rem" }} variant="h6"></Typography> <br></br>

              </Col>
              <Col xs={6} md={4}>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="วันที่เริ่ม"
                      value={values.datestartinsert} // access the date value from the state
                      onChange={handleChangeData('datestartinsert')} // update the date value in the state
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </FormControl>

              </Col>
              <Col xs={6} md={4}>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="วันสิ้นสุด"
                      value={values.dateendinsert} // access the date value from the state
                      onChange={handleChangeData('dateendinsert')} // update the date value in the state
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </FormControl>

              </Col>


            </Row>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {zone?.map((row, index) => (
                      <TableCell align="center" sx={{ fontSize: 16 }} key={row.zone_id}>{row.zone_name}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {zone?.map((row, index) => (
                      <TableCell align="center" key={row.zone_id}>
                        <TextField
                          hiddenLabel
                          size="small"
                          variant="filled"
                          defaultValue={0}
                          type="number"
                          InputProps={{
                            readOnly: false,
                          }}
                          inputProps={{
                            style: { textAlign: 'center' } // Center the text
                          }}
                          required
                          fullWidth
                          value={inputValues[row.zone_id] || ''}
                          onChange={handleChangeInsert(row.zone_id)}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Divider sx={{ marginBottom: 2, marginTop: 2 }} />

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="success" size="large" onClick={() => handleSaveInsert()}>
            บันทึก
          </Button>
          <Button variant="contained" color="error" size="large" onClick={() => setDialogOpenInsert(false)}>
            ยกเลิก
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default ManagePatient;
