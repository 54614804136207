import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Stack,
  IconButton,
  Drawer,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Notification from "../Notification/index";

class NavbarComponent extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <AppBar
        position="fixed"
        elevation={1}
        style={{ zIndex: 1000 }}
        sx={{
          backgroundColor: "#F5F5F5",
          height: 70,
          color: "#272D43",
          width: this.props.open
            ? this.props.size.width < this.props.breakwidth
              ? "100%"
              : `calc(100% - ${this.props.drawerwidth}px)`
            : "100%",
          ml: `${this.props.open ? this.props.drawerwidth : 0}px`,
        }}
      >
        <Toolbar sx={{ height: "100%" }}>
          {!this.props.open && this.props.size.width > this.props.breakwidth && (
            <IconButton
              aria-label="delete"
              size="large"
              onClick={this.props.slideBarOnClick}
            >
              <MenuIcon
                sx={{
                  color: "#272D43",
                }}
              />
            </IconButton>
          )}

          {this.props.size.width < this.props.breakwidth && (
            <IconButton
              aria-label="delete"
              size="large"
              onClick={this.props.slideBarOnClick}
            >
              <MenuIcon
                sx={{
                  color: "#272D43",
                }}
              />
            </IconButton>
          )}

          <Stack
            direction="row"
            justifyContent="flex-start"
            spacing={1}
            alignItems="center"
            sx={{ flexGrow: 1 }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            ระบบการจองคิวล่วงหน้า
              (Advance booking system)
            </Typography>
            {/* <Typography
              variant="h6"
              sx={{ fontWeight: "600", color: "#5872e9" }}
            >
              (วันจันทร์ที่ 9 ตุลาคม 2565 16:12 น.)
            </Typography> */}
          </Stack>
        </Toolbar>
      </AppBar>
    );
  }
}

const Navbar = (props) => {
  return <NavbarComponent {...props} />;
};

export default Navbar;
