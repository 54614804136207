import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useNavigate   } from "react-router-dom";
import "react-table-6/react-table.css"
import CancelIcon from '@mui/icons-material/Cancel';
import { TablePagination } from '@mui/material';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import EditIcon from '@mui/icons-material/Edit';

import SaveIcon from '@mui/icons-material/Save';
import {
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    TextField,
    Box,
    Divider,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    IconButton,
} from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import styled from "styled-components";


const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top:  0;
  margin-bottom: 2em;
`;

const Assignment = styled.div`

    background-color: #AED6F1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width:80px;
  
`

const SettingUser = () => {
    const [user, setUser] = useState([{}]);
    const [password, setpassword] = useState("");
    const [loading, setLoading] = useState(true);
    const [position, setPosition] = useState([]);
    const [editPosition, setEditPosition] = useState(0);
    const [editMode, setEditMode] = useState(false); // for toggling edit mode

    const token = localStorage.getItem("SavedToken");
    const userId = localStorage.getItem("user").replace('"','').replace('"','');
    useEffect(() => {
       
    }, []);

    const handlePositionChange = (event) => {
        setEditPosition(event.target.value);
    };

    const handleEditClick = () => {
        setEditMode(true);
    };
    const [values, setValues] = useState({
      });
    
      const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
      };
    const navigate  = useNavigate ();
    const handleSaveClick = () => {
        const updatedUser = { 
            "user_username": user[0].user_username,
            "user_password": values.password,
            "user_fname": user[0].user_fname,
            "user_lname": user[0].user_lname,
            "user_tel": user[0].user_tel,
            "user_status": user[0].user_status,
            "user_position_id": user[0].user_position_id
        };
        console.log(updatedUser)

        axios
            .put(`${process.env.REACT_APP_BASE_API_URL}/manage/user/${userId}`, updatedUser, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                //setUser(res.data);
                setEditMode(false);
                Swal.fire('Updated!', 'The user has been updated.', 'success');
              
            }).catch(err => {
                console.log(err);
                Swal.fire('Error!', err.response.data.detail, 'error');
              });
        


    };

    const handleChangeData = (prop) => (event) => {
        setUser([{ ...user[0], [prop]: event.target.value }]);
    };
    
    
      

    return (
        <div>
            <HeaderContainer>
                <Typography variant="h1" fontWeight="700">
                    ยังไม่ได้ทำครับ
                </Typography>
               
            </HeaderContainer>
        </div>

    );
};

export default SettingUser;