import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import CloseIcon from "@mui/icons-material/Close";
import { TablePagination } from '@mui/material';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  TextField,
  Box,
  Stack,
} from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import styled from "styled-components";

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 2em;
`;

const ManageUser = () => {

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [position, setPosition] = useState([]);
  let [searchPosition, setsearchPosition] = useState('0');
  const [isLoading, setIsLoading] = useState(true);
  const [positiongname, setpositiongname] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const rowsCount = users.length;





  useEffect(() => {
    const token = localStorage.getItem("SavedToken");
    axios
      .get(process.env.REACT_APP_BASE_API_URL + "/manage/user/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setUsers(res.data);
        setLoading(false);
      });
    axios
      .get(process.env.REACT_APP_BASE_API_URL + "/manage/position/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setPosition(res.data);
      })
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleAdd = () => {
    const token = localStorage.getItem("SavedToken");
    setIsLoading(true);

    axios
      .get(process.env.REACT_APP_BASE_API_URL + "/manage/position/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setPosition(res.data);
        setIsLoading(false);
        console.log(position);

        Swal.fire({
          title: "Add User",
          html: `
            <input id="user_username" class="swal2-input" placeholder="Username" type="text">
            <input id="user_password" class="swal2-input" placeholder="Password" type="text">
            <input id="user_fname" class="swal2-input" placeholder="Fname" type="text">
            <input id="user_lname" class="swal2-input" placeholder="Lname" type="text">
            <input id="user_tel" class="swal2-input" placeholder="Tel" type="text">
            <select id="user_position_id" class="swal2-input">
              ${position.map(
            (pos) =>
              `<option value="${pos.id}">${pos.name}</option>`
          )}
            </select>
          `,
          showCancelButton: true,
          confirmButtonText: "Add",
          preConfirm: () => {
            const recent_user_id = localStorage.getItem('user').replace(/"/g, '');
            const user_username = document.getElementById("user_username").value;
            const user_password = document.getElementById("user_password").value;
            const user_fname = document.getElementById("user_fname").value;
            const user_lname = document.getElementById("user_lname").value;
            const user_tel = document.getElementById("user_tel").value;
            const user_position_id = document.getElementById("user_position_id").value;
            console.log("user_position_id", user_position_id);
            return {
              user_username,
              user_password,
              user_fname,
              user_lname,
              user_tel,
              user_position_id,
              recent_user_id
            };
          },
        }).then((result) => {
          if (result.value) {
            console.log(result.value);
            Swal.fire({
              title: 'Loading...',
              allowOutsideClick: false,
              allowEscapeKey: false,
              didOpen: () => {
                Swal.showLoading()
              }
            });
            axios
              .post(
                process.env.REACT_APP_BASE_API_URL + "/manage/user/", result.value,{
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((res) => {
                axios
                  .get(process.env.REACT_APP_BASE_API_URL + "/manage/user/", {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  })
                  .then((res) => {
                    setUsers(res.data);
                    setLoading(false);
                  });
                Swal.fire("Added!", "The user has been added.", "success");
              })
              .catch((err) => {
                console.log(err);
                Swal.fire("Error!", err.response.data.detail, "error");
              });
          }
        });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire("Error!", err.response.data.detail, "error");
      });

    if (isLoading) {
      return <div>Loading...</div>;
    }
  };


  const handleEdit = (user) => {
    const token = localStorage.getItem('SavedToken');

    axios
      .get(process.env.REACT_APP_BASE_API_URL + '/manage/position/', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        const position = res.data;
        Swal.fire({
          title: 'Edit User',
          html: `

            <input id="user_fname" class="swal2-input" placeholder="Fname" type="text" value="${user.user_fname}">
            <input id="user_lname" class="swal2-input" placeholder="Lname" type="text" value="${user.user_lname}">
            <input id="user_tel" class="swal2-input" placeholder="Tel" type="text" value="${user.user_tel}">
            <select id="user_position_id" class="swal2-input">
              ${position.map(pos => `<option value="${pos.id}" ${pos.name === user.position_name ? 'selected' : ''}>${pos.name}</option>`)}
            </select>
            <div class="swal2-checkbox">
              <input type="checkbox" id="user_status" ${user.user_status == "1" ? "checked" : ""}>
              Active
            </div>
          `,
          
          inputValidator: (value) => {
            if (!value.user_position_id) {
              Swal.fire('Error!', 'Please select a position.', 'error');
              return '';
            }
          },
          showCancelButton: true,
          confirmButtonText: 'Save',
          preConfirm: () => {
            const user_username = user.user_username;
            const recent_user_id = localStorage.getItem('user').replace(/"/g, '');

            const user_fname = document.getElementById('user_fname').value;
            const user_lname = document.getElementById('user_lname').value;
            const user_tel = document.getElementById('user_tel').value;
            const user_position_id = document.getElementById('user_position_id').value;
            let user_status = '';
            if(document.getElementById("user_status").checked){
               user_status = '1'
            }else{
               user_status = '0'
            }
            return { user_username, user_fname, user_lname, user_tel, user_position_id, user_status ,recent_user_id };
          }
        }).then(result => {
          if (result.value) {
            Swal.fire({
              title: 'Loading...',
              allowOutsideClick: false,
              allowEscapeKey: false,
              didOpen: () => {
                Swal.showLoading()
              }
            });

            axios.put(process.env.REACT_APP_BASE_API_URL + `/manage/user/${user.user_id}/admin`, result.value, {
              headers: {
                Authorization: `Bearer ${token}`
              }
            })
              .then(res => {
                axios.get(process.env.REACT_APP_BASE_API_URL + '/manage/user/', {
                  headers: {
                    Authorization: `Bearer ${token}`
                  }
                })
                  .then(res => {
                    setUsers(res.data);
                    setLoading(false);
                  });
                Swal.fire('Updated!', 'The user has been updated.', 'success');
              })
              .catch(err => {
                console.log(err);
                Swal.fire('Error!', err.response.data.detail, 'error');
              });
          }
        });
      })
      .catch(err => {
        console.log(err);
        Swal.fire('Error!', err.response.data.detail, 'error');
      });
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: 'Loading...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
            Swal.showLoading()
          }
        });
        const token = localStorage.getItem("SavedToken");
        axios
          .delete(process.env.REACT_APP_BASE_API_URL + `/manage/user/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setUsers(users.filter((user) => user.user_id !== id));
            Swal.fire("Deleted!", "The user has been deleted.", "success");
          }).catch(err => {
            console.log(err);
            Swal.fire('Error!', err.response.data.detail, 'error');
          });
      }
    });
  };

  const handleResetPassword = (userId) => {
    const token = localStorage.getItem("SavedToken");
    console.log(token);

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reset",
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: 'Loading...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
            Swal.showLoading()
          }
        });
        axios
        .get(process.env.REACT_APP_BASE_API_URL + `/manage/user/resetpassword/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          Swal.fire("Reset!", "Password reset successfully", "success");
        }).catch(err => {
          console.log(err);
          Swal.fire('Error!', err.response.data.detail, 'error');
        });
  

      }
    });
       

  }

  const [values, setValues] = useState({});

  const handleChangeData = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!values.searchUsername) {
      values.searchUsername = "";
    }
    if (!values.searchFname) {
      values.searchFname = "";
    }
    if (!values.searchLname) {
      values.searchLname = "";
    }
    if (!values.searchTel) {
      values.searchTel = "";
    }
    if (!searchPosition) {
      searchPosition = '0';
    }

    console.log(searchPosition)

    const searchData = {
      user_username: values.searchUsername,
      user_password: '',
      user_fname: values.searchFname,
      user_lname: values.searchLname,
      user_tel: values.searchTel,
      user_position_id: searchPosition
    };
    const token = localStorage.getItem("SavedToken");
    console.log(searchData);
    axios.post(process.env.REACT_APP_BASE_API_URL + '/manage/user/search', searchData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => {
        console.log(res.data);
        setUsers(res.data);
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        Swal.fire('Error!', err.response.data.detail, 'error');
      });
  };
  const handlePositionChange = (event) => {
    setsearchPosition(event.target.value);
  };


  return (
    <>
      <HeaderContainer>
        <Typography variant="h5" fontWeight="700">
          รายการพนักงาน
        </Typography>
        <Button
          variant="contained"
          style={{ backgroundColor: "#59CE8F" }}
          sx={{ marginLeft: "1rem" }}
          onClick={handleAdd}
        >
          <AddCircleIcon sx={{ marginRight: ".5rem" }} />
          เพิ่มพนักงาน
        </Button>
      </HeaderContainer>

      <Paper sx={{ padding: "1rem", marginTop: "-1.5rem" }}>
        <Typography fontWeight="700" variant="h6">ค้นหาระบุ</Typography>

        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs={4} md={2}>
              <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                <TextField
                  id="searchUsername"
                  label="Username"
                  variant="outlined"
                  onChange={handleChangeData("searchUsername")}
                  fullWidth
                />
              </FormControl>
            </Col>
            <Col xs={4} md={2}>
              <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                <TextField
                  id="searchFname"
                  label="ชื่อจริง"
                  variant="outlined"
                  onChange={handleChangeData("searchFname")}

                  fullWidth
                />
              </FormControl>
            </Col>
            <Col xs={4} md={2}>
              <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                <TextField
                  id="searchLname"
                  label="นามสกุล"
                  variant="outlined"
                  onChange={handleChangeData("searchLname")}

                  fullWidth
                />
              </FormControl>
            </Col>
            <Col xs={4} md={2}>
              <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                <TextField
                  id="searchTel"
                  label="เบอร์โทร"
                  variant="outlined"
                  onChange={handleChangeData("searchTel")}

                  fullWidth
                />
              </FormControl>
            </Col>
            <Col xs={4} md={2}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <InputLabel id="show_user_position_id">ตำแหน่ง</InputLabel>
                <Select
                  labelId="show_user_position_id"
                  id="searchPosition"
                  value={searchPosition}
                  label="ตำแหน่ง"
                  onChange={handlePositionChange}
                >
                  <MenuItem
                    value="0" defaultValue>ALL</MenuItem>
                  {position.map(pos => (
                    <MenuItem
                      key={pos.id}
                      value={pos.id}
                    >
                      {pos.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
            <Col xs={4} md={2}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <Button variant="contained" size="large" onClick={handleSubmit}>
                  ค้นหา
                </Button>
              </FormControl>
            </Col>

          </Row>


        </form>
      </Paper>
      <br></br>
      <Typography display="inline" variant="body2" style={{ color: "#5872E9" }} > <CheckCircleIcon style={{ color: "#36AE7C" }} /> รายการพนักงานสำเร็จ {rowsCount} รายการ  </Typography>
      <Typography display="inline" variant="body2" style={{ color: "#AAAAAA" }} > | </Typography>
      <Typography display="inline" variant="body2" style={{ color: "#5872E9" }} > <CancelIcon style={{ color: "#EB5353" }} /> รายการพนักงานไม่สำเร็จ 0 รายการ  </Typography>
      <br></br><br></br>

      {loading ? (
        <p>Loading...</p>
      ) : (

        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: '#F5F5F5' }}>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", fontSize: 18 }} >ID</TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 18 }}>Username</TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 18 }}>ชื่อจริง</TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 18 }}>นามสกุล</TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 18 }}>เบอร์โทร</TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 18 }}>ตำแหน่ง</TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", fontSize: 18 }}>สถานะ</TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 18 }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <TableRow key={row.user_id}>
                  <TableCell sx={{ fontSize: 16 }} component="th" scope="row">{row.user_id}</TableCell>
                  <TableCell sx={{ fontSize: 16 }}>{row.user_username}</TableCell>
                  <TableCell sx={{ fontSize: 16 }}>{row.user_fname}</TableCell>
                  <TableCell sx={{ fontSize: 16 }}>{row.user_lname}</TableCell>
                  <TableCell sx={{ fontSize: 16 }}>{row.user_tel}</TableCell>
                  <TableCell sx={{ fontSize: 16 }}>{row.position_name}</TableCell>

                  <TableCell align="center">{row.user_status == '1' ? <CheckCircleIcon fontSize="large" style={{ color: "#4caf50" }} /> : <CancelIcon fontSize="large" style={{ color: "#f44336" }} />}</TableCell>
                  <TableCell>
                    <Button variant="contained" style={{ backgroundColor: "#FFCB42", color: "#000000" }} onClick={() => handleEdit(row)}>
                      <DriveFileRenameOutlineIcon color="primary" /> Edit
                    </Button>
                    <Button variant="contained" style={{ backgroundColor: "#008CBA", color: "#fff", marginLeft: '1rem' }} onClick={() => handleResetPassword(row.user_id)}>
                      <LockOpenIcon /> Reset Password
                    </Button>
                    <Button variant="contained" style={{ backgroundColor: "#DC143C", color: "#fff", marginLeft: '1rem' }} onClick={() => handleDelete(row.user_id)}>
                      <CloseIcon /> Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <br></br>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={rowsCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>


      )}
    </>
  );
};

export default ManageUser;
