import GuardRoutes from "./apps/guards";
import GuardRoutesDiagnose from "./apps/guards_diagnose";
import GuardRoutesManageuser from "./apps/guards_manageuser";
import GuardRoutesManageposition_permissions from "./apps/guards_manageposition_permissions";
import GuardRoutesManagpatients from "./apps/guards_managepatients";
import GuardRoutesTest from "./apps/guardstest";
import GuardRoutesReport from "./apps/guards_report";

import Login from "./apps/views/login/routing";
import List from "./apps/views/list/routing";
import ListDay from "./apps/views/listday/routing";

import Dashboard from "./apps/views/dashboard/routing";
import Home from "./apps/views/home/routing";
import ManageUser from "./apps/views/manage/user/routing";
import CycletTime from "./apps/views/manage/cycletime/routing";
import Group from "./apps/views/manage/group/routing";

import SettingUser from "./apps/views/settinguser/routing";
import Booking from "./apps/views/booking/routing";
import BookingChange from "./apps/views/bookingchange/routing";

import Bookinglist from "./apps/views/bookinglist/routing";
import BookinglistInfo from "./apps/views/bookinglistinfo/routing";
import BookinglistWarn from "./apps/views/bookinglistwarn/routing";
import BookinglisError from "./apps/views/bookinglisterror/routing";

import UploadSlip from "./apps/views/uploadslip/routing";

import ReportStoreView  from "./apps/views/reportStoreView/routing"; 
import ReportStoreSelect  from "./apps/views/reportStoreSelect/routing"; 
import ReportKitchenSelect  from "./apps/views/reportKitchenSelect/routing"; 



const routes = [
  {
    path: '/*',
    children:Login ,
  },
  {
    path: '*',
    children:Login ,
  },
  {
    path: 'index',
    children:Login ,
  },
  {
    path: 'index.html',
    children:Login ,
  },
  {
    path: '/index.html',
    children:Login ,
  },
  {
    path: '/index',
    children:Login ,
  },
  { path: "/uploadslip/:date/:idgen", children: UploadSlip },

  { path: "login", children: Login },


  
  { path: "", 
    element: <GuardRoutes />,
    children: [{ children: List }]}, 
  {
    path: "/day/:startdate/:enddate",
    element: <GuardRoutes />,
    children: [{ children: ListDay }],
  },

  {
    path: "/booking/:date",
    element: <GuardRoutes />,
    children: [{ children: Booking }],
  },

  {
    path: "/bookingchange/:date/:reserve_id",
    element: <GuardRoutes />,
    children: [{ children: BookingChange }],
  },


  {
    path: "/bookinglist",
    element: <GuardRoutes />,
    children: [{ children: Bookinglist }],
  },
  {
    path: "/bookinglist/info",
    element: <GuardRoutes />,
    children: [{ children: BookinglistInfo }],
  },
  {
    path: "/bookinglist/warn",
    element: <GuardRoutes />,
    children: [{ children: BookinglistWarn }],
  },

  {
    path: "/bookinglist/error",
    element: <GuardRoutes />,
    children: [{ children: BookinglisError }],
  },
  
  {
    path: "/dashboard",
    element: <GuardRoutes />,
    children: [{ children: Dashboard }],
    
  },
  {
    path: "/report/store/Select",
    element: <GuardRoutes />,
    children: [{ children: ReportStoreSelect }],
  },
  {
    path: "/report/kitchen/Select",
    element: <GuardRoutes />,
    children: [{ children: ReportKitchenSelect }],
  },
  {
    path: "/report/kitchen/Select/View",
    element: <GuardRoutes />,
    children: [{ children: ReportKitchenSelect }],
  },
  {
    path: "/report/store/view/:date",
    element: <GuardRoutesReport />,
    children: [{ children: ReportStoreView }],
  },
  {
    path: "/report/kitchen/viwe",
    element: <GuardRoutes />,
    children: [{ children: SettingUser }],
  },


  {
    path: "/manage/customer",
    element: <GuardRoutes />,
    children: [{ children: SettingUser }],
  },
  {
    path: "/manage/zone",
    element: <GuardRoutes />,
    children: [{ children: SettingUser }],
  },
  {
    path: "/manage/sessions",
    element: <GuardRoutes />,
    children: [{ children: SettingUser }],
  },
  ,
  {
    path: "/manage/setmeal",
    element: <GuardRoutes />,
    children: [{ children: SettingUser }],
  },
  ,
  {
    path: "/manage/bookingperday",
    element: <GuardRoutes />,
    children: [{ children: SettingUser }],
  },
  {
    path: "/manage/cycletime",
    element: <GuardRoutes />,
    children: [{ children: CycletTime }],
  },
  {
    path: "/manage/group",
    element: <GuardRoutes />,
    children: [{ children: Group }],
  },
  {
    path: "/setting",
    element: <GuardRoutes />,
    children: [{ children: SettingUser }],
  },
  
];

export default routes;
