import React, { useState, useEffect, useLayoutEffect } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import QRCode from "react-qr-code";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-table-6/react-table.css"
import CancelIcon from '@mui/icons-material/Cancel';
import { TablePagination } from '@mui/material';
import { Row, Col, Container } from 'react-bootstrap';
import FileUpload from "react-material-file-upload";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import EditIcon from '@mui/icons-material/Edit';
import { useParams } from 'react-router-dom';


import SaveIcon from '@mui/icons-material/Save';
import {
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    TextField,
    Box,
    Divider,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    IconButton,
} from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import styled from "styled-components";
import i18n from './i18n'

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top:  1.5em;
  margin-bottom: 2em;
`;

const UploadSlip = () => {
    const [files, setFiles] = useState([]);
    const { t } = useTranslation();

    var checkLanguageCurrent = localStorage.getItem("language");

    const [language, setLanguage] = useState(checkLanguageCurrent);
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [booking, setBooking] = useState([]);
    const [rescode, setRescode] = useState('');
    const [slip, setSlip] = useState('');
    const [status, setStatus] = useState('');


    let { date, idgen } = useParams();

    const loaddata = () => {
        setSlip('')
        setFiles([])
        const token = localStorage.getItem('SavedToken');


        axios.get(process.env.REACT_APP_BASE_API_URL + `/api/booking/uploadslip/customer/${idgen}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                setCustomers(res.data);
                console.log(res.data)
                if (res.data.length <= 0) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Please try again',
                        text: `ไม่พบข้อมูลการจอง กรุณาติดต่อเจ้าหน้าที่`
                    });
                } else {
                    Swal.close();
                }

                res.data.map((item, index) => (
                    setStatus(item.status_name)
                ))

                res.data.map(item => {
                    const newRescode = item.reserve_id;
                    setRescode(newRescode);

                    axios.get(process.env.REACT_APP_BASE_API_URL + `/api/booking/uploadslip/booking/${newRescode}`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                        .then(res => {
                            setBooking(res.data);
                            console.log(res.data)
                            if (res.data.length <= 0) {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Please try again',
                                    text: `ไม่พบข้อมูลการจอง กรุณาติดต่อเจ้าหน้าที่`
                                });
                            } else {
                                Swal.close();
                            }

                            axios.get(process.env.REACT_APP_BASE_API_URL + `/api/booking/uploadslip/${newRescode}`, {
                                headers: {
                                    Authorization: `Bearer ${token}`
                                }
                            })
                                .then(res => {
                                    setSlip(res.data);
                                    console.log(res.data)
                                    if (res.data.length <= 0) {
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Please try again',
                                            text: `ไม่พบข้อมูลการจอง กรุณาติดต่อเจ้าหน้าที่`
                                        });
                                    } else {
                                        Swal.close();
                                    }

                                    Swal.close();

                                });

                        });
                });

                setLoading(false);
            });
    }

    useEffect(() => {
        Swal.fire({
            title: 'Loading...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });

        if (i18n.changeLanguage) {
            if (checkLanguageCurrent === null) {
                console.log("this case");
                localStorage.setItem("language", "en");
                i18n.changeLanguage("en");
            } else {
                localStorage.setItem("language", language);
                i18n.changeLanguage(language);
            }
        }

        loaddata()

    }, [checkLanguageCurrent, i18n, language]);



    const [values, setValues] = useState({
    });


    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    const navigate = useNavigate();

    const handleButtonLanguageChange = (event) => {
        if (language === "en") {
            setLanguage("th");
            i18n.changeLanguage("th");
            localStorage.setItem("language", "th");

        } else if (language === "th") {
            setLanguage("en");
            i18n.changeLanguage("en");
            localStorage.setItem("language", "th");

        } else {
            setLanguage("en");
            i18n.changeLanguage("en");
            localStorage.setItem("language", "en");

        }
    };

    const handleConfirm = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Confirm it!",
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    title: 'Loading...',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                });
                const token = localStorage.getItem("SavedToken");
                axios
                    .put(process.env.REACT_APP_BASE_API_URL + `/api/booking/${rescode}`, {}, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then((res) => {
                        Swal.fire("Confirmed!", res.data, "success").then((result) => {
                            if (result.value) {
                                loaddata()
                            }
                        })


                    }).catch(err => {
                        console.log(err);
                        Swal.fire('Error!', err.response.data.detail, 'error');
                    });
            }
        });
    }

    const handleDelete = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Delete it!",
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    title: 'Loading...',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                });
                const token = localStorage.getItem("SavedToken");
                axios
                    .delete(process.env.REACT_APP_BASE_API_URL + `/api/booking/${rescode}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then((res) => {
                        Swal.fire("Deleted!", "The user has been deleted.", "success").then((result) => {
                            if (result.value) {
                                navigate(-1)
                            }
                        })



                    }).catch(err => {
                        console.log(err);
                        Swal.fire('Error!', err.response.data.detail, 'error');
                    });
            }
        });
    }

    const handleSubmit = async e => {
        e.preventDefault();
        const formData = new FormData();

        // Create a new File object with a new name.
        const newFile = new File([files[0]], `${date.replaceAll("-", "")}-${idgen}-${rescode}`, { type: files[0].type });

        formData.append('file', newFile);
        formData.append('data', 'testsend'); // add the filename to the form data

        Swal.fire({
            title: 'Loading...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });

        const token = localStorage.getItem('SavedToken');
        axios.post(process.env.REACT_APP_BASE_API_URL + "/api/booking/uploadslip",
            formData,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'

                },
            })
            .then(res => {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Upload success",
                }).then((result) => {
                    if (result.isConfirmed) {
                        loaddata()
                    }
                });


            });

    }


    const formatDate = (date) => {
        const newDate = new Date(date);
        const year = newDate.getFullYear();
        const month = String(newDate.getMonth() + 1).padStart(2, '0');
        const day = String(newDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    return (
        <div>

            <HeaderContainer fluid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
                <Typography variant="h4" fontWeight="700">
                    {t('title')}
                </Typography>
                <IconButton
                    onClick={handleButtonLanguageChange}
                    color="inherit"
                    aria-label="switch"
                    style={{ fontSize: '3em' }} // add this to increase the size of IconButton

                >
                    {language === "th" ? (
                        <ReactCountryFlag
                            countryCode="TH"
                            svg
                            cdnSuffix="svg"
                            title="THAI"
                        />
                    ) : (
                        <ReactCountryFlag
                            countryCode="GB"
                            svg
                            cdnSuffix="svg"
                            title="ENGLISH"
                        />
                    )}
                </IconButton>

            </HeaderContainer>
            <Row >
                <Col xs={0} md={4}>
                </Col>
                <Col xs={12} md={4}>
                    <Paper sx={{ padding: "1rem", margin: "1rem", marginTop: "-2rem" }}>
                        <Typography sx={{ marginBottom: "1rem" }} fontWeight="700" variant="h5">รายละเอียด ข้อมูลลูกค้า</Typography>
                        {customers.map((item, index) => (
                            <div key={index}>

                                <Row >
                                    <Col xs={12} md={12} >
                                        <Typography variant="h6"><b>เลขที่การจอง : </b>{item.reserve_id}</Typography>
                                    </Col>

                                </Row>
                                <Row >
                                    <Col xs={12} md={7}>
                                        <Typography variant="h6"><b>ชื่อผู้จอง : </b>{item.reserve_customer_name}</Typography>
                                    </Col>
                                    <Col xs={12} md={5}>
                                        <Typography variant="h6"><b>ประเภทลูกค้า : </b>ทั่วไป</Typography>
                                    </Col>

                                </Row>
                                <Row >
                                    <Col xs={5} md={7}>
                                        <Typography variant="h6"><b>จำนวน : </b> {item.reserve_count} <b>คน</b></Typography>
                                    </Col>
                                    <Col xs={7} md={5}>
                                        <Typography variant="h6"><b>เงินมัดจำ : </b> {item.reserve_deposit} <b>บาท</b></Typography>
                                    </Col>

                                </Row>
                                <Row >
                                    <Typography variant="h6"><b>สถานะ : </b>
                                        <span style={{ backgroundColor: '#FED523', paddingLeft: '5px', paddingRight: '5px', color: '#000', borderRadius: '10px' }}
                                        >{item.status_name}</span>
                                    </Typography>
                                </Row>
                            </div>
                        ))}
                    </Paper>
                </Col>
                <Col xs={0} md={4}>
                </Col>
            </Row>
            <br></br>
            {localStorage.getItem('SavedToken') && status != 'ยืนยันการจอง' ?
                <>
                    <Row>
                        <Col xs={0} md={4}></Col>
                        <Col xs={12} md={4}>
                            <Paper sx={{ padding: "1rem", margin: "1rem", marginTop: "-2rem" }}>
                                <Typography sx={{ marginBottom: "1rem" }} fontWeight="700" variant="h5">จัดการการจอง</Typography>
                                <Row>
                                    <Col xs={6} md={6}>
                                        <Button variant="contained" size="large" style={{
                                            backgroundColor: "#07B53B",
                                            marginRight: "1rem",
                                            width: "100%"

                                        }}
                                            onClick={handleConfirm}
                                        >ยืนยันการจอง

                                        </Button>
                                    </Col>
                                    <Col xs={6} md={6}>
                                        <Button variant="contained" size="large" style={{
                                            backgroundColor: "#EB5353",
                                            marginRight: "1rem",
                                            width: "100%"

                                        }}

                                            onClick={handleDelete}
                                        >
                                            ยกเลิกการจอง
                                        </Button>
                                    </Col>



                                </Row>
                            </Paper>

                        </Col>
                        <Col xs={0} md={4}></Col>
                    </Row>
                    <br></br>
                </>
                : ""

            }

            <Row >
                <Col xs={0} md={4}>
                </Col>
                <Col xs={12} md={4}>
                    <Paper sx={{ padding: "1rem", margin: "1rem", marginTop: "-2rem" }}>
                        <Typography sx={{ marginBottom: "1rem" }} fontWeight="700" variant="h5">รายละเอียด ข้อมูลการจอง</Typography>

                        <Row >
                            {booking.map((item, index) => (
                                <div key={index}>
                                    <Typography variant="h6"><b>วันที่ :</b> {formatDate(item.around_date)}</Typography>
                                    <Typography variant="h6"><b>โซน :</b> {item.zone_name}</Typography>
                                    <Typography variant="h6"><b>รอบ :</b> {item.around_starttime}-{item.around_endtime}</Typography>
                                    <Typography variant="h6"><b>จำนวน :</b> {item.num_rows} โต๊ะ</Typography>

                                    <Divider sx={{ marginBottom: 2, marginTop: 2 }} />
                                </div>
                            ))}
                            <Col>
                                <Typography variant="h6"><b>มีจำนวนทั้งหมด :</b> {booking.reduce((total, item) => total + item.num_rows, 0)}  โต๊ะ</Typography>
                            </Col>

                        </Row>

                    </Paper>
                </Col>
                <Col xs={0} md={4}>
                </Col>
            </Row>
            <br></br>

            <Row >
                <Col xs={0} md={4}>
                </Col>
                <Col xs={12} md={4}>
                    <Paper sx={{ padding: "1rem", margin: "1rem", marginTop: "-2rem" }}>
                        <Typography sx={{ marginBottom: "1rem" }} fontWeight="700" variant="h5">QR Code การจอง</Typography>
                        {status == 'ยืนยันการจอง' ?
                            <Row
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >

                                <QRCode
                                    size={256}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={rescode}
                                    viewBox={`0 0 256 256`}
                                />

                            </Row>
                            :
                            <Typography sx={{ marginBottom: "1rem" }} variant="h6"> - ไม่พบ QR Code การจอง</Typography>
                        }

                    </Paper>
                </Col>

                <Col xs={0} md={4}>
                </Col>
            </Row>
            <br></br>

            <Row >
                <Col xs={0} md={4}>
                </Col>
                <Col xs={12} md={4}>
                    <Paper sx={{ padding: "1rem", margin: "1rem", marginTop: "-2rem" }}>
                        <Typography sx={{ marginBottom: "1rem" }} fontWeight="700" variant="h5">ประวัติการอัพโหลดสลิป </Typography>
                        {slip.length > 0 ?
                            <Row
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '100%',  // Add this to ensure the Row fills its parent's height
                                }}
                            >
                                {
                                    Array.isArray(slip) && slip.map((item, index) => (
                                        <div key={index}>
                                            <img width="100%" src={`${process.env.REACT_APP_BASE_API_URL}/uploads/${date.replaceAll("-", "")}/${item.slip_name}`} alt="Your image description" />

                                        </div>
                                    ))
                                }


                            </Row>
                            :
                            <Typography sx={{ marginBottom: "1rem" }} variant="h6"> - ไม่พบประวัติการอัพโหลดสลิป </Typography>
                        }

                    </Paper>

                </Col>

                <Col xs={0} md={4}>
                </Col>
            </Row>
            <br></br>

            {status != 'ยืนยันการจอง' && customers ?
                <Row >
                    <Col xs={0} md={4}>
                    </Col>
                    <Col xs={12} md={4}>
                        <Paper sx={{ padding: "1rem", margin: "1rem", marginTop: "-2rem" }}>

                            <Typography sx={{ marginBottom: "1rem" }} fontWeight="700" variant="h5">อัพโหลดสลิป</Typography>

                            <FileUpload
                                value={files}
                                onChange={setFiles}
                                buttonText="เลือกรูปสลิป"
                                accept="image/*"
                            />
                            <FormControl sx={{ marginTop: "1rem", width: "100%" }}>
                                <Button sx={{height:"80px" , fontSize:"24px"}} variant="contained" size="large" onClick={handleSubmit}>
                                    บันทึกสลิปโอนเงิน
                                </Button>
                            </FormControl>

                        </Paper>
                    </Col>
                    <Col xs={0} md={4}>
                    </Col>
                </Row>
                : ""}
        </div>

    );
};

export default UploadSlip;