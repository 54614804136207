import { createTheme } from "@mui/material/styles";
import Prompt from "@fontsource/prompt";

const light = createTheme({
  typography: {
    fontFamily: `"Prompt", sans-serif`,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": [Prompt],
      },
    },
  },
});
export default light;
