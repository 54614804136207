import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import Main from "apps/shared/components/main";
const GuardRoutes = ({ component: Component, ...rest }) => {
    return (
      
      <Main>
        
        <Outlet {...rest} />
      </Main>
    );
  
};

export default GuardRoutes;
