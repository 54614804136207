import React, { useState, useEffect } from "react";
import {
  styled, darken, alpha, lighten,
} from '@mui/material/styles';
import Swal from 'sweetalert2';
import axios from 'axios';

import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TableCell from '@mui/material/TableCell';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { ViewState, EditingState } from '@devexpress/dx-react-scheduler';
import classNames from 'clsx';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import SummarizeIcon from '@mui/icons-material/Summarize';
import {
  Scheduler,
  MonthView,
  Appointments,
  Toolbar,
  DateNavigator,
  AppointmentTooltip,
  AppointmentForm,
  EditRecurrenceMenu,
  DragDropProvider,
} from '@devexpress/dx-react-scheduler-material-ui';
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  TextField,
  Box,
  Stack,
} from "@mui/material";

import Block from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';

import { useNavigate } from "react-router-dom";

const PREFIX = 'Demo';

const classes = {
  cell: `${PREFIX}-cell`,
  content: `${PREFIX}-content`,
  text: `${PREFIX}-text`,
  sun: `${PREFIX}-sun`,
  cloud: `${PREFIX}-cloud`,
  rain: `${PREFIX}-rain`,
  sunBack: `${PREFIX}-sunBack`,
  cloudBack: `${PREFIX}-cloudBack`,
  rainBack: `${PREFIX}-rainBack`,
  opacity: `${PREFIX}-opacity`,
  appointment: `${PREFIX}-appointment`,
  apptContent: `${PREFIX}-apptContent`,
  flexibleSpace: `${PREFIX}-flexibleSpace`,
  flexContainer: `${PREFIX}-flexContainer`,
  tooltipContent: `${PREFIX}-tooltipContent`,
  tooltipText: `${PREFIX}-tooltipText`,
  title: `${PREFIX}-title`,
  icon: `${PREFIX}-icon`,
  circle: `${PREFIX}-circle`,
  textCenter: `${PREFIX}-textCenter`,
  dateAndTitle: `${PREFIX}-dateAndTitle`,
  titleContainer: `${PREFIX}-titleContainer`,
  container: `${PREFIX}-container`,
};

const getBorder = theme => (`1px solid ${theme.palette.mode === 'light'
  ? lighten(alpha(theme.palette.divider, 1), 0.88)
  : darken(alpha(theme.palette.divider, 1), 0.68)
  }`);

const DayScaleCell = props => (
  <MonthView.DayScaleCell {...props} style={{ textAlign: 'center', fontWeight: 'bold' }} />
);
const HeaderContainer = styled('div')(() => ({
  [`&.${classes.head}`]: {
    display: "flex",
    alignItems: "center",
    marginTop: "0",
    marginBottom: "2em"
  },
}));

const StyledEmpty = styled(SummarizeIcon)(() => ({
  [`&.${classes.sun}`]: {
    color: '#1ABC9C',
  },
}));
const StyledBlock = styled(SummarizeIcon)(() => ({
  [`&.${classes.sun}`]: {
    color: '#E74C3C',
  },
}));


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${classes.cell}`]: {
    color: '#78909C!important',
    position: 'relative',
    userSelect: 'none',
    verticalAlign: 'top',
    padding: 0,
    height: 100,
    borderLeft: getBorder(theme),
    '&:first-of-type': {
      borderLeft: 'none',
    },
    '&:last-child': {
      paddingRight: 0,
    },
    'tr:last-child &': {
      borderBottom: 'none',
    },
    '&:hover': {
      backgroundColor: 'white',
    },
    '&:focus': {
      backgroundColor: alpha(theme.palette.primary.main, 0.15),
      outline: 0,
    },
  },
  [`&.${classes.full}`]: {
    backgroundColor: '#FADBD8',
  },
  [`&.${classes.cloudBack}`]: {
    backgroundColor: '#ECEFF1',
  },
  [`&.${classes.rainBack}`]: {
    backgroundColor: '#E1F5FE',
  },
  [`&.${classes.opacity}`]: {
    opacity: '0.5',
  },
}));
const StyledDivText = styled('div')(() => ({
  [`&.${classes.text}`]: {
    padding: '0.5em',
    textAlign: 'center',
  },
}));
const StyledDivContent = styled('div')(() => ({
  [`&.${classes.content}`]: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute',
    alignItems: 'center',
  },
}));

const StyledAppointmentsAppointment = styled(Appointments.Appointment)(() => ({
  [`&.${classes.appointment}`]: {
    borderRadius: '10px',
    '&:hover': {
      opacity: 0.6,
    },
  },
}));

const StyledToolbarFlexibleSpace = styled(Toolbar.FlexibleSpace)(() => ({
  [`&.${classes.flexibleSpace}`]: {
    flex: 'none',
  },
  [`& .${classes.flexContainer}`]: {
    display: 'flex',
    alignItems: 'center',
  },
}));
const StyledAppointmentsAppointmentContent = styled(Appointments.AppointmentContent)(() => ({
  [`&.${classes.apptContent}`]: {
    '&>div>div': {
      whiteSpace: 'normal !important',
      lineHeight: 1.2,
    },
  },
}));

const WeatherIcon = ({ id }) => {
  switch (id) {
    case 1:
      return <StyledEmpty className={classes.sun} fontSize="large" />;
    case 2:
      return <StyledBlock className={classes.sun} fontSize="large" />;
    default:
      return null;
  }
};

export default function Demo() {
  document.title = 'ปฏิทินการจอง - ระบบการจองคิวล่วงหน้า (Advance booking system)';
  const navigate = useNavigate();

  const [currentDate, setCurrentDate] = useState(new Date().toISOString().split('T')[0]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [data, setData] = useState([]);


  const formatDate = (date) => {
    const newDate = new Date(date);
    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, '0');
    const day = String(newDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

  useEffect(() => {
  }, []);

  const CellBase = React.memo(({
    startDate,
    formatDate,
    otherMonth,
    handleOpenDialog,

  }) => {
    const formatDateLocal = date => {
      const offsetMs = date.getTimezoneOffset() * 60 * 1000;
      const msLocal = date.getTime() - offsetMs;
      const dateLocal = new Date(msLocal);
      const iso = dateLocal.toISOString();
      const isoShort = iso.slice(0, 10);

      return isoShort;

    }
    const dateStr = formatDateLocal(startDate);
    let iconId = 1;
   
    const isFirstMonthDay = startDate.getDate() === 1;
    const formatOptions = isFirstMonthDay
      ? { day: 'numeric', month: 'long' }
      : { day: 'numeric' };

    const handleCellClick = () => {
      if (iconId === 1) {
        handleOpenDialog(formatDateLocal(startDate));
      }
    };

    return (
      <StyledTableCell
        tabIndex={0}
        className={classNames({
          [classes.cell]: true,
          [classes.full]: iconId === 2,

        })}
        onClick={handleCellClick}

      >
        <StyledDivContent className={classes.content}>
          <WeatherIcon classes={classes} id={iconId} />
        </StyledDivContent>
        <StyledDivText className={classes.text}>
          {formatDate(startDate, formatOptions)}
        </StyledDivText>
      </StyledTableCell>
    );
  });

  const TimeTableCell = (CellBase);

  const Appointment = (({ ...restProps }) => (
    <StyledAppointmentsAppointment
      {...restProps}
      className={classes.appointment}
    />
  ));

  const AppointmentContent = (({ ...restProps }) => (
    <StyledAppointmentsAppointmentContent {...restProps} className={classes.apptContent} />
  ));

  const FlexibleSpace = (({ ...restProps }) => (
    <StyledToolbarFlexibleSpace {...restProps} className={classes.flexibleSpace}>

    </StyledToolbarFlexibleSpace>
  ));

  const handleOpenDialog = (date) => {
    setSelectedDate(date);
    navigate(`/report/store/view/${date}`)
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const currentDateChange = (current) => {
    const now = new Date();
    const currentMonthYear = new Date(current).getFullYear() * 100 + new Date(current).getMonth();
    const nowMonthYear = now.getFullYear() * 100 + now.getMonth();
  
      setCurrentDate(current);
    
  }

  const onSubmit = () => {
    setSubmitted(true);
  }



  const commitChanges = ({ added, changed, deleted }) => {
    let updatedData = data;
    if (added) {
      const startingAddedId = data.length > 0 ? data[data.length - 1].id + 1 : 0;
      updatedData = [...data, { id: startingAddedId, ...added }];
    }
    if (changed) {
      updatedData = data.map(appointment => (
        changed[appointment.id] ? { ...appointment, ...changed[appointment.id] } : appointment));
    }
    if (deleted !== undefined) {
      updatedData = data.filter(appointment => appointment.id !== deleted);
    }
    setData(updatedData);
  }

  return (
    <>

      <HeaderContainer>
        <Typography variant="h5" fontWeight="700">
          รายการการจองคิวร้านอาหาร
        </Typography>

      </HeaderContainer>
      
      <Paper sx={{ padding: "1rem", marginTop: "0rem" }}>
        <Scheduler>
          <EditingState
            onCommitChanges={commitChanges}
          />
          <ViewState
            currentDate={currentDate}
            onCurrentDateChange={currentDateChange}
          />

          <MonthView
            timeTableCellComponent={(props) => <TimeTableCell {...props} handleOpenDialog={handleOpenDialog} />}
          />
          <Appointments
            appointmentComponent={Appointment}
            appointmentContentComponent={AppointmentContent}
          />

          <Toolbar
          />
          <DateNavigator />

          <EditRecurrenceMenu />
          <AppointmentTooltip
            showCloseButton
            showDeleteButton
            showOpenButton
          />
          <AppointmentForm />
          <DragDropProvider />
        </Scheduler>
      </Paper>
     
    </>
  );
}