// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard_bg__xyYlX {
  position: absolute;
  z-index: 10;
}

.dashboard_bg_text__Nl45U {
  position: fixed;
  bottom: 1rem;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dashboard_puff_out_center__IRlxu {
  position: fixed;
  z-index: 1;
  animation: dashboard_puff-out-center__b3b3n 1s linear 1s infinite both;
}
@keyframes dashboard_puff-out-center__b3b3n {
  0% {
    transform: scale(1);
    filter: blur(0px);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    filter: blur(4px);
    opacity: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/apps/views/dashboard/index.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,UAAU;EAEV,sEAAqD;AACvD;AAkBA;EACE;IAEE,mBAAmB;IAEnB,iBAAiB;IACjB,UAAU;EACZ;EACA;IAEE,mBAAmB;IAEnB,iBAAiB;IACjB,UAAU;EACZ;AACF","sourcesContent":[".bg {\n  position: absolute;\n  z-index: 10;\n}\n\n.bg_text {\n  position: fixed;\n  bottom: 1rem;\n  z-index: 10;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.puff_out_center {\n  position: fixed;\n  z-index: 1;\n  -webkit-animation: puff-out-center 1s linear 1s infinite both;\n  animation: puff-out-center 1s linear 1s infinite both;\n}\n\n@-webkit-keyframes puff-out-center {\n  0% {\n    -webkit-transform: scale(1);\n    transform: scale(1);\n    -webkit-filter: blur(0px);\n    filter: blur(0px);\n    opacity: 1;\n  }\n  100% {\n    -webkit-transform: scale(2);\n    transform: scale(2);\n    -webkit-filter: blur(4px);\n    filter: blur(4px);\n    opacity: 0;\n  }\n}\n@keyframes puff-out-center {\n  0% {\n    -webkit-transform: scale(1);\n    transform: scale(1);\n    -webkit-filter: blur(0px);\n    filter: blur(0px);\n    opacity: 1;\n  }\n  100% {\n    -webkit-transform: scale(2);\n    transform: scale(2);\n    -webkit-filter: blur(4px);\n    filter: blur(4px);\n    opacity: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bg": `dashboard_bg__xyYlX`,
	"bg_text": `dashboard_bg_text__Nl45U`,
	"puff_out_center": `dashboard_puff_out_center__IRlxu`,
	"puff-out-center": `dashboard_puff-out-center__b3b3n`
};
export default ___CSS_LOADER_EXPORT___;
