import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';

import { useParams } from 'react-router-dom';
import "react-table-6/react-table.css"
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { format, addDays } from 'date-fns';


import {
    Typography,

} from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import styled from "styled-components";

const HeaderContainer = styled.div`
  align-items: center;
  margin-top:  -0.7em;
  margin-bottom: -1em;
  align: center
`;

const PrintableComponent = forwardRef((props, ref) => {

    document.title = 'รายงาน - ระบบการจองคิวล่วงหน้า (Advance booking system)';

    const [rows, setRows] = useState([]);
    const componentRef = useRef();

    let { date } = useParams();

    const [loading, setLoading] = useState(true);
    const textRef = useRef();

    const [values, setValues] = useState({
        date: date,
        dateend: null,
        count_cus: 0,
        deposit: 0,
        paid: false,
    });

    const loaddata = (datedata, zone) => {
        const token = localStorage.getItem('SavedToken');


        Swal.fire({
            title: 'Loading...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });

        axios.get(process.env.REACT_APP_BASE_API_URL + `/api/booking/${datedata}?zone_id=${zone}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                setRows(res.data);
                console.log(res.data)
                setLoading(false);
                if (res.data <= 0) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Please try again',
                        text: `ไม่พบข้อมูลรอบเวลาของวันที่ ${datedata} กรุณาเพิ่มข้อมูลและลองใหม่อีกครั้ง`
                    });
                } else {
                    Swal.close();
                }

            });
    }

    useEffect(() => {
        loaddata(date, "%")

    }, []);

    function formatTime(timeString) {
        let times = timeString.split(' - ');
        let startTime = times[0].substring(0, 5);
        let endTime = times[1].substring(0, 5);
        return (
            <>
                {startTime}
                <br />
                <br />
                {endTime}
            </>
        );
    }
    return (

        <div ref={ref}
            style={{
                width: "260mm",
                height: "356mm",
                paddingLeft: "5mm",
                paddingRight: "5mm",
                paddingTop: "5mm",
                paddingBottom: "5mm",
            }}
        >
            <HeaderContainer>
                <Typography align="center" variant="h6" fontWeight="700">
                    การจองคิวล่วงหน้า วันที่ {date}
                </Typography>

            </HeaderContainer>
            <>
                <Row style={{ width: "100%", marginTop: "1rem" }}>
                    <Col xs={12} md={12}>

                        <TableContainer component={Paper} >
                            {rows.map((row, rowIndex) => {
                                const maxTotals = Math.max(...row.zone.map(zone => zone.total));

                                return (
                                    <Table key={rowIndex}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" style={{ border: '1px solid #AAA', width: '3%', height: '5mm', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', padding: '0.1em' }}>
                                                    รอบ/โซน
                                                </TableCell>
                                                <TableCell align="center" style={{ border: '1px solid #AAA', width: '1%', height: '5mm', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', padding: '0.1em' }}>
                                                </TableCell>
                                                {row.zone.map((zone) => (
                                                    <TableCell align="right" style={{ border: '1px solid #AAA', width: '12%', height: '5mm', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', padding: '0.1em' }}>
                                                        {zone.zone}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>


                                        <TableBody>
                                            {Array.from({ length: maxTotals }).map((_, index) => {
                                                const mergedCells = [];
                                                return (
                                                    <TableRow
                                                        key={`${row.around}|${index}`}
                                                        style={{ height: '5mm', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', padding: '0.1em' }}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        {index === 0 && (
                                                            <TableCell align="center" component="th" scope="row" rowSpan={maxTotals} style={{ border: '1px solid #AAA', width: '50mm', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', padding: '0.1em' }}>
                                                                {formatTime(row.around)}
                                                            </TableCell>
                                                        )}
                                                        <TableCell align="center" style={{ border: '1px solid #AAA', width: '1%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', padding: '0.5em' }}>
                                                            {index + 1}
                                                        </TableCell>
                                                        {row.zone.map((zone, zoneIndex) => {
                                                            const rowspan = zone.row.length - index;
                                                            const merged = mergedCells.includes(zoneIndex) ? 'none' : '';
                                                            if (index < zone.row.length) {
                                                                if (zone.row[index].name === null || zone.row[index].name === '') {
                                                                    mergedCells.push(zoneIndex);
                                                                    return (
                                                                        <TableCell
                                                                            align="left"
                                                                            key={`${row.around}|${index}|${zoneIndex}`}
                                                                            style={{
                                                                                border: '1px solid #AAA',
                                                                                height: '5mm',
                                                                                width: '12%',
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                                whiteSpace: 'nowrap',
                                                                                padding: '0.1em',
                                                                                fontSize: '12px',
                                                                                backgroundColor: merged,
                                                                            }}
                                                                            rowSpan={rowspan}
                                                                        >
                                                                            {index < zone.row.length && (
                                                                                <>
                                                                                    {zone.row[index].name}
                                                                                    <br />
                                                                                    <span
                                                                                        style={{
                                                                                            display: 'inline-block',
                                                                                            borderRadius: '50%',
                                                                                            backgroundColor: '#FFCB42',
                                                                                            width: '22px',
                                                                                            height: '22px',
                                                                                            lineHeight: '22px',
                                                                                            textAlign: 'center',
                                                                                        }}
                                                                                    >
                                                                                        {zone.row[index].count}
                                                                                    </span>
                                                                                    {zone.row[index].deposit}฿ v3
                                                                                    <br />
                                                                                    {zone.row[index].note}
                                                                                </>
                                                                            )}
                                                                        </TableCell>
                                                                    );
                                                                } else if (index === 0 || zone.row[index].name !== zone.row[index - 1].name) {
                                                                    const count = zone.row.filter((row) => row.name === zone.row[index].name).length;
                                                                    mergedCells.push(zoneIndex);
                                                                    return (
                                                                        <TableCell
                                                                            align="left"
                                                                            key={`${row.around}|${index}|${zoneIndex}`}
                                                                            style={{
                                                                                border: '1px solid #AAA',
                                                                                height: '5mm',
                                                                                width: '12%',
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                                whiteSpace: 'nowrap',
                                                                                padding: '0.1em',
                                                                                fontSize: '12px',
                                                                                backgroundColor: merged,
                                                                            }}
                                                                            rowSpan={count}
                                                                        >
                                                                            {index < zone.row.length && (
                                                                                <>
                                                                                    {zone.row[index].name}
                                                                                    <br />
                                                                                    <span
                                                                                        style={{
                                                                                            display: 'inline-block',
                                                                                            borderRadius: '50%',
                                                                                            backgroundColor: '#FFCB42',
                                                                                            width: '22px',
                                                                                            height: '22px',
                                                                                            lineHeight: '22px',
                                                                                            textAlign: 'center',
                                                                                        }}
                                                                                    >
                                                                                        {zone.row[index].count}
                                                                                    </span>
                                                                                    {zone.row[index].deposit}฿ 
                                                                                    <br />
                                                                                    {zone.row[index].note}
                                                                                </>
                                                                            )}
                                                                        </TableCell>
                                                                    );
                                                                }
                                                            }else{
                                                                
                                                                  return( <TableCell
                                                                    align="left"
                                                                    key={`${row.around}|${index}|${zoneIndex}`}
                                                                    style={{
                                                                        border: '1px solid #AAA',
                                                                        height: '5mm',
                                                                        width: '12%',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        whiteSpace: 'nowrap',
                                                                        padding: '0.1em',
                                                                        fontSize: '12px',
                                                                        backgroundColor: merged,
                                                                    }}
                                                                ></TableCell>
                                                                ) 
                                                                        
                                                            }
                                                            return null;
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                );
                            })}
                        </TableContainer>

                    </Col>
                </Row>
            </>

        </div>
    );
});

export default PrintableComponent;
