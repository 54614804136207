import { useRoutes } from "react-router-dom";
import routes from "./routes";
import './index.css';
import { ThemeProvider } from "@mui/material/styles";
import LightTheme from "apps/shared/themes/light";
import "apps/utils/icons";

function App() {
  let element = useRoutes(routes);
  return <ThemeProvider theme={LightTheme}>{element}</ThemeProvider>;
}

export default App;
