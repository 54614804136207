import React from "react";
import { Box, Avatar, Stack, Typography } from "@mui/material";

class AvartarComponent extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const fname = String(localStorage.getItem('fname')).replaceAll('"','');
    const position_name = String(localStorage.getItem('position_name')).replaceAll('"','');
    return (
      <Box style={{ zIndex: 19 }}
        sx={{
          margin: 2,
          marginBottom: 1,
          color: "#272D43",
          backgroundColor: "#FED523",
          borderRadius: 2,
          paddingLeft: 2,
          paddingRight: 2,
          minHeight: 75,
        }}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={{ height: "100%" }}
          spacing={2}
        >
          <Avatar sx={{ bgcolor: "white", color: "#272D43" }}>P</Avatar>
          <Stack justifyContent="flex-start" spacing={0}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              {fname}
            </Typography>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: 'white' }}>
              ({position_name})
            </Typography>
          </Stack>
        </Stack>
      </Box>
    );
  }
}

export default AvartarComponent;
