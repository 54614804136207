import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import CloseIcon from "@mui/icons-material/Close";
import { TablePagination } from '@mui/material';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import LoupeIcon from '@mui/icons-material/Loupe';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';


import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Divider,
  TextField,
  Box,
  Stack,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,

} from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import styled from "styled-components";

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 2em;
`;

const ManagePatient = () => {

  const [cycletime, setCycletime] = useState([]);
  const [loading, setLoading] = useState(true);
  const [patient, setPatient] = useState([]);
  let [searchSex, setsearchSex] = useState('0');
  const [isLoading, setIsLoading] = useState(true);
  const [positiongname, setpositiongname] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const rowsCount = cycletime.length;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogOpenInsert, setDialogOpenInsert] = useState(false);
  const [around, setAround] = useState([]);
  const [selectedStartTime, setSelectedStartTime] = useState();
  const [selectedEndTime, setSelectedEndTime] = useState();
  const [editRowIndex, setEditRowIndex] = useState(null);

  const [selectedStartTimeInsert, setSelectedStartTimeInsert] = useState();
  const [selectedEndTimeInsert, setSelectedEndTimeInsert] = useState();

  const [timeSlots, setTimeSlots] = useState([{ startTime: null, endTime: null }]);
  const [updatedTimes, setUpdatedTimes] = useState({});


  const handleAddTimeSlot = () => {
    setTimeSlots([...timeSlots, { startTime: null, endTime: null }]);
  };

  const handleTimeChange = (index, type, newValue) => {
    const newTimeSlots = [...timeSlots];
    newTimeSlots[index][type] = newValue;
    setTimeSlots(newTimeSlots);
  };

  const handleEdit = (index) => {
    setEditRowIndex(index);
  };

  const handleCancel = () => {
    setSelectedStartTime(null);
    setSelectedEndTime(null);
    setEditRowIndex(null);
  };

  const handleSave = () => {
    console.log(updatedTimes);  // log or use updatedTimes as required
    Swal.fire({
      title: 'Loading...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });
    const token = localStorage.getItem('SavedToken');
        axios.put(process.env.REACT_APP_BASE_API_URL + `/api/cycletime`, updatedTimes, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
          .then(res => {
            axios
            .get(process.env.REACT_APP_BASE_API_URL + "/api/cycletime/", {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              setCycletime(res.data);
              Swal.fire('Saved!', 'The cycletime has been edited.', 'success');

            }).catch(err => {
              console.log(err)
              Swal.fire('Error!', err.response.data.detail, 'error');
            })
            
          })
          .catch(err => {
            console.log(err)
            Swal.fire('Error!', err.response.data.detail, 'error');
          })
          setDialogOpen(false)
    setSelectedStartTime(null);
    setSelectedEndTime(null);
    setEditRowIndex(null);
  };


  const handleSaveInsert = () => {

    try {
      Swal.fire({
        title: 'Loading...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading()
        }
      });

      const formattedTimeSlots = timeSlots.map(slot => {
        return {
          startTime: formatTime(slot.startTime),
          endTime: formatTime(slot.endTime)
        };
      });

      const data = {
        "dateStart": formatDate(values.datestartinsert),
        "dateEnd": formatDate(values.dateendinsert),
        "around": formattedTimeSlots
      }

      console.log(data);
      const token = localStorage.getItem('SavedToken');

      axios
        .post(process.env.REACT_APP_BASE_API_URL + "/api/cycletime/", data, {
          headers: {
            Authorization: `Bearer ${token}`
          }

        })
        .then((response) => {
          console.log("response booking", response);
          if (response.data.message === "Success") {
            Swal.fire({
              icon: "success",
              title: "Updated success",
              text: response.data.message,
            });
            const token = localStorage.getItem("SavedToken");
            axios
              .get(process.env.REACT_APP_BASE_API_URL + "/api/cycletime/", {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((res) => {
                setCycletime(res.data);
              });
        
          } else {
            Swal.fire({
              icon: "error",
              title: "",
              text: response.data.detail
            });
          }
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response && error.response.data && error.response.data.detail
              ? error.response.data.detail
              : "ไม่สามารถติดต่อ server ได้",
          });
        });
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response && error.response.data && error.response.data.detail
          ? error.response.data.detail
          : "ไม่สามารถติดต่อ server ได้",
      });
    }

    setTimeSlots([{ startTime: null, endTime: null }]);

    setDialogOpenInsert(false);
  };


  useEffect(() => {
    const token = localStorage.getItem("SavedToken");
    axios
      .get(process.env.REACT_APP_BASE_API_URL + "/api/cycletime/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setCycletime(res.data);
        setLoading(false);
      });

  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleAdd = () => {
    const token = localStorage.getItem("SavedToken");


  };


  const handleEditMain = (user) => {
    const token = localStorage.getItem('SavedToken');

  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: 'Loading...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
            Swal.showLoading()
          }
        });
        const token = localStorage.getItem("SavedToken");
        axios
          .delete(process.env.REACT_APP_BASE_API_URL + `/manage/patient/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setCycletime(cycletime.filter((cycletime) => cycletime.patient_id !== id));
            Swal.fire("Deleted!", "The user has been deleted.", "success");
          }).catch(err => {
            console.log(err);
            Swal.fire('Error!', err.response.data.detail, 'error');
          });
      }
    });
  };


  const [values, setValues] = useState({
    datestart: null,
    dateend: null,
    datestartinsert: null,
    dateendinsert: null,
    count_cus: 0,
    deposit: 0,
    paid: false,
    zone: "%"
  });

  const handleChangeData = (prop) => (event) => {
    if (prop === 'paid') {
      setValues({ ...values, [prop]: event.target.checked });
    } else if (prop === 'count_cus') {
      let value = event.target.value;
      let deposit = value * 300;
      setValues({ ...values, 'count_cus': value, 'deposit': deposit });
    } else if (prop === 'datestart' || prop === 'dateend' || prop === 'datestartinsert' || prop === 'dateendinsert') {
      let value = event;
      setValues({ ...values, [prop]: value });
    } else {
      let value = event.target.value;

      setValues({ ...values, [prop]: value });
    }


  };

  const handleSubmit = (e) => {
    e.preventDefault();
    Swal.fire({
      title: 'Loading...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    let datestart = '' 
    let dateend = '' 
    if (!values.datestart) {
      datestart = "2000-01-01";
    }else{
      datestart = formatDate(values.datestart)
    }
    if (!values.dateend) {
      dateend = "2999-01-01";
    }else{
      dateend = formatDate(values.dateend)
    }

    const token = localStorage.getItem("SavedToken");
    axios.get(process.env.REACT_APP_BASE_API_URL + `/api/cycletime/${datestart}/${dateend}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => {
        console.log(res.data);
    
        setCycletime(res.data);
        Swal.close();
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        Swal.fire('Error!', err.response.data.detail, 'error');
      });
  };
  const handleSexChange = (event) => {
    setsearchSex(event.target.value);
  };
  const handleOpen = (event) => {
    setDialogOpen(true)

    const token = localStorage.getItem("SavedToken");
    axios
      .get(process.env.REACT_APP_BASE_API_URL + "/api/cycletime/" + formatDate(event.around_date), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setAround(res.data);
        console.log(res.data)
      });


  }

  const formatDate = (date) => {
    const newDate = new Date(date);
    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, '0');
    const day = String(newDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const formatTime = (date) => {
    const newDate = new Date(date);
    var hours = newDate.getHours();
    var minutes = newDate.getMinutes();
    var seconds = newDate.getSeconds();

    // add leading zeros
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;


    return `${hours}:${minutes}:${seconds}`;
  }


  return (
    <>
      <HeaderContainer>
        <Typography variant="h5" fontWeight="700">
          รายการรอบเวลา
        </Typography>
        <Button
          variant="contained"
          style={{ backgroundColor: "#59CE8F" }}
          sx={{ marginLeft: "1rem" }}
          onClick={() => setDialogOpenInsert(true)}
        >
          <AddCircleIcon sx={{ marginRight: ".5rem" }} />
          เพิ่มรอบเวลา
        </Button>
      </HeaderContainer>

      <Paper sx={{ padding: "1rem", marginTop: "-1.5rem" }}>
        <Typography fontWeight="700" variant="h6">ค้นหาระบุ</Typography>

        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs={6} md={3}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="วันที่เริ่ม"
                    value={values.datestart} // access the date value from the state
                    onChange={handleChangeData('datestart')} // update the date value in the state
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>

            </Col>
            <Col xs={6} md={3}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="วันสิ้นสุด"
                    value={values.dateend} // access the date value from the state
                    onChange={handleChangeData('dateend')} // update the date value in the state
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>

            </Col>
            <Col xs={6} md={4}></Col>
            <Col xs={4} md={2}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <Button variant="contained" size="large" onClick={handleSubmit}>
                  ค้นหา
                </Button>
              </FormControl>
            </Col>

          </Row>


        </form>
      </Paper>
      <br></br>
      <Typography display="inline" variant="body2" style={{ color: "#5872E9" }} > <CheckCircleIcon style={{ color: "#36AE7C" }} /> รายการพนักงานสำเร็จ {rowsCount} รายการ  </Typography>
      <Typography display="inline" variant="body2" style={{ color: "#AAAAAA" }} > | </Typography>
      <Typography display="inline" variant="body2" style={{ color: "#5872E9" }} > <CancelIcon style={{ color: "#EB5353" }} /> รายการพนักงานไม่สำเร็จ 0 รายการ  </Typography>
      <br></br><br></br>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: '#F5F5F5' }}>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", fontSize: 18 }}>วันที่</TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 18 }}>รอบเวลาเริ่มต้น</TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 18 }}>รอบเวลาสิ้นสุด</TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 18 }}>รอบเวลาทั้งหมด</TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 18 }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cycletime.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <TableRow key={row.around_id}>
                  <TableCell sx={{ fontSize: 16 }} component="th" scope="row">{formatDate(row.around_date)}</TableCell>
                  <TableCell sx={{ fontSize: 16 }}>{row.min_time}</TableCell>
                  <TableCell sx={{ fontSize: 16 }}>{row.max_time}</TableCell>
                  <TableCell sx={{ fontSize: 16 }}>{row.count_cycletime} รอบ</TableCell>
                  <TableCell>

                    <Button variant="contained" style={{ backgroundColor: "#FFCB42", color: "#000000", marginLeft: '1rem' }} onClick={() => handleOpen(row)}>
                      <LoupeIcon color="primary" /> รายละเอียด
                    </Button>

                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <br></br>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={rowsCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>




      )}
      <Dialog
      fullWidth
      maxWidth="md"
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle style={{ cursor: 'move', fontSize: 26 }} id="alert-dialog-title">รายละเอียด</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {around.map((row, index) => {
            let timeStart = row.around_starttime;
            let timeEnd = row.around_endtime;
            let minutesStart = timeStart.substring(0, timeStart.indexOf(":") + 3);
            let secondsStart = timeStart.substring(timeStart.lastIndexOf(":") + 1);
            let formattedTimeStart = `${minutesStart} ${secondsStart}`;
            let minutesEnd = timeEnd.substring(0, timeEnd.indexOf(":") + 3);
            let secondsEnd = timeEnd.substring(timeEnd.lastIndexOf(":") + 1);
            let formattedTimeEnd = `${minutesEnd} ${secondsEnd}`;

            return (
              <div key={row.around_id}>
                <Row>
                  <Col xs={12} md={2}>
                    <Typography sx={{ marginTop: "0.7rem" }} variant="h6">รอบที่ {index + 1}</Typography> <br></br>
                  </Col>
                  <Col xs={12} md={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        ampm={false}
                        value={selectedStartTime && editRowIndex === index ? selectedStartTime : formattedTimeStart}
                        onChange={(newValue) => {
                          setSelectedStartTime(newValue);
                          setUpdatedTimes({
                            ...updatedTimes,
                            [row.around_id]: {
                              ...updatedTimes[row.around_id],
                              startTime: formatTime(newValue),
                            },
                          });
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        disabled={editRowIndex !== index}
                      />
                    </LocalizationProvider>
                  </Col>
                  <Col xs={12} md={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        ampm={false}
                        value={selectedEndTime && editRowIndex === index ? selectedEndTime : formattedTimeEnd}
                        onChange={(newValue) => {
                          setSelectedEndTime(newValue);
                          setUpdatedTimes({
                            ...updatedTimes,
                            [row.around_id]: {
                              ...updatedTimes[row.around_id],
                              endTime: formatTime(newValue),
                            },
                          });
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        disabled={editRowIndex !== index}
                      />
                    </LocalizationProvider>
                  </Col>
                  <Col xs={12} md={4}>
                    {editRowIndex !== index ? (
                      <Button sx={{ marginTop: "0.7rem" }} onClick={() => handleEdit(index)} variant="contained" style={{ backgroundColor: "#FFCB42", color: "#000000", marginLeft: '1rem' }}>
                        <DriveFileRenameOutlineIcon color="primary" /> แก้ไข
                      </Button>
                    ) : (
                      <>

                        <Button sx={{ marginTop: "0.7rem", marginLeft: '1rem' }} onClick={handleSave} variant="contained" color="success">
                          บันทึก
                        </Button>
                        <Button sx={{ marginTop: "0.7rem", marginLeft: '1rem' }} onClick={handleCancel} variant="contained" color="error">
                          ยกเลิก
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>
                <Divider sx={{ marginBottom: 2, marginTop: 2 }} />
              </div>
            );
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" size="large" onClick={() => setDialogOpen(false)}>
          ตกลง
        </Button>
      </DialogActions>
    </Dialog>

      <Dialog
        fullWidth
        maxWidth="md"
        open={dialogOpenInsert}
        onClose={() => setDialogOpenInsert(false)}
        aria-labelledby="alert-dialog-title-insert"
        aria-describedby="alert-dialog-description-insert"
      >
        <DialogTitle style={{ cursor: 'move', fontSize: 26 }} id="alert-dialog-title-insert">เพิ่มรอบเวลา</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-insert">
            <Row>
              <Col xs={6} md={2}>
                <Typography sx={{ marginTop: "0.7rem" }} variant="h6">ช่วงเวลา</Typography> <br></br>

              </Col>
              <Col xs={6} md={4}>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="วันที่เริ่ม"
                      value={values.datestartinsert} // access the date value from the state
                      onChange={handleChangeData('datestartinsert')} // update the date value in the state
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </FormControl>

              </Col>
              <Col xs={6} md={4}>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="วันสิ้นสุด"
                      value={values.dateendinsert} // access the date value from the state
                      onChange={handleChangeData('dateendinsert')} // update the date value in the state
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </FormControl>

              </Col>


            </Row>
            <Divider sx={{ marginBottom: 2, marginTop: 2 }} />

            {timeSlots.map((slot, index) => (
              <div key={index}>
                <Row>
                  <Col xs={12} md={3}>
                    <Typography sx={{ marginTop: "0.7rem" }} variant="h6">รอบที่ {index + 1}</Typography> <br></br>
                  </Col>
                  <Col xs={12} md={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        ampm={false}
                        value={slot.startTime}
                        onChange={(newValue) => {
                          handleTimeChange(index, 'startTime', newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Col>
                  <Col xs={12} md={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        ampm={false}
                        value={slot.endTime}
                        onChange={(newValue) => {
                          handleTimeChange(index, 'endTime', newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Col>
                </Row>
                <Divider sx={{ marginBottom: 2, marginTop: 2 }} />
              </div>
            ))}
            <Button variant="contained" onClick={handleAddTimeSlot}>เพิ่มรอบ</Button>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="success" size="large" onClick={() => handleSaveInsert()}>
            บันทึก
          </Button>
          <Button variant="contained" color="error" size="large" onClick={() => setDialogOpenInsert(false)}>
            ยกเลิก
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default ManagePatient;
