import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import CloseIcon from "@mui/icons-material/Close";
import { TablePagination } from '@mui/material';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CopyIcon from '@mui/icons-material/FileCopy';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import BorderColorIcon from '@mui/icons-material/BorderColor';

import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  TextField,
  Box,
  Stack,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,

} from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import LoupeIcon from '@mui/icons-material/Loupe';


const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 2em;
`;

const ManageReserve = () => {
  document.title = 'รายการจอง - ระบบการจองคิวล่วงหน้า (Advance booking system)';
  const navigate = useNavigate();

  const [reserves, setReserves] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const [loading, setLoading] = useState(true);
  const [position, setPosition] = useState([]);
  let [searchPosition, setsearchPosition] = useState('%');
  const [isLoading, setIsLoading] = useState(true);
  const [positiongname, setpositiongname] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const rowsCount = reserves.length;
  const [dialogOpen, setDialogOpen] = useState(false);

  const [dialogContent, setDialogContent] = useState(null);




  useEffect(() => {

    const token = localStorage.getItem("SavedToken");
    Swal.fire({
      title: 'Loading...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });
    axios
      .get(process.env.REACT_APP_BASE_API_URL + "/api/booking/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setReserves(res.data);
        setLoading(false);
      });
    Swal.close();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleEdit = (reserve) => {
    const token = localStorage.getItem('SavedToken');
    Swal.fire('Error!', 'ยังไม่ได้ทำครับ', 'error');

  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: 'Loading...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
            Swal.showLoading()
          }
        });
        const token = localStorage.getItem("SavedToken");
        axios
          .delete(process.env.REACT_APP_BASE_API_URL + `/manage/reserve/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setReserves(reserves.filter((reserve) => reserve.reserve_id !== id));
            Swal.fire("Deleted!", "The reserve has been deleted.", "success");
          }).catch(err => {
            console.log(err);
            Swal.fire('Error!', err.response.data.detail, 'error');
          });
      }
    });
  };


  const [values, setValues] = useState({
    date: null,
    id : '',
    customer_name: '',
    customer_tel: '',
    count_cus: 0,
    deposit: 0,
    setfoot: '',
    count_foot: 0,
    note: ''

    // ... other properties ...
  });

  const handleChangeData = (prop) => (event) => {
    if (prop === 'paid') {
      setValues({ ...values, [prop]: event.target.checked });

    } else if (prop === 'count_cus') {
      let value = event.target.value;
      let deposit = value * 300;
      setValues({ ...values, 'count_cus': value, 'deposit': deposit });
    } else if (prop === 'date') {
      let value = event;
      setValues({ ...values, [prop]: value });
    } else if (prop === 'dateend') {
      let value = event;
      setValues({ ...values, [prop]: value });
    } else {
      let value = event.target.value;
      setValues({ ...values, [prop]: value });
    }
  };
  const handleSubmit = (e) => {
    try {
      const data = {
        "res_id": values.searchReserveid,
        "date": formatDate(values.date),
        "res_name": values.searchname,
        "status":""
      };

      console.log(data)
      Swal.fire({
        title: 'Loading...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading()
        }
      });
      const token = localStorage.getItem('SavedToken');

      axios
        .post(process.env.REACT_APP_BASE_API_URL + "/api/booking/search/", data, {
          headers: {
            Authorization: `Bearer ${token}`
          }

        })
        .then((response) => {
          console.log("response booking", response);
          if (response.data.message === "Success") {
            console.log(response.data.detail)
            setReserves(response.data.detail);
            Swal.close()
          } else {
            Swal.close()
          }
        });
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response && error.response.data && error.response.data.detail
          ? error.response.data.detail
          : "ไม่สามารถติดต่อ server ได้",
      });
    }
  };

  const handleChange = (data) => {

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Change this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Change it!",
    }).then((result) => {
      if (result.value) {
        navigate(data);

      }
    });
  }
  const handlePositionChange = (event) => {
    setsearchPosition(event.target.value);
  };

  const formatDate = (date) => {
    const newDate = new Date(date);
    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, '0');
    const day = String(newDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const handleCopy = (textRef) => {
    if (textRef) {
      const text = 'http://reservation.bubblethailand.com' + textRef
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text);
      } else {
        // Fallback for browsers that do not support clipboard API
        var textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand('copy');
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }
        document.body.removeChild(textArea);
      }
      Swal.fire('Copy URL', 'copy url success', 'success');

    }
  };

  const handleOpenDialog = async (reserve_id) => {

    setIsOpen(true);
    setValues({
      ...values,
      id: '',
      customer_name: '',
      customer_tel: '',
      count_cus: '',
      deposit: '',
      note: '',
      setfoot: '',
      count_foot: '',

    });

    try {
      const token = localStorage.getItem('SavedToken');

      const response = await axios.get(process.env.REACT_APP_BASE_API_URL + `/api/booking/byid/${reserve_id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.data <= 0) {
        Swal.fire({
          icon: 'error',
          title: 'Please try again',
          text: `ไม่พบข้อมูล กรุณาเพิ่มข้อมูลและลองใหม่อีกครั้ง`
        });
        return;
      }
      console.log(response)
      setValues({
        ...values,
        id: response.data[0].reserve_id,
        customer_name: response.data[0].reserve_customer_name,
        customer_tel: response.data[0].reserve_customer_tel ? response.data[0].reserve_customer_tel : '',
        count_cus: response.data[0].reserve_count,
        deposit: response.data[0].reserve_deposit,
        note: response.data[0].reserve_note ? response.data[0].reserve_note : '',
        setfoot: response.data[0].reserve_footsetid ? response.data[0].reserve_footsetid : '',
        count_foot: response.data[0].reserve_footset_cnt ? response.data[0].reserve_footset_cnt : ''

      });

    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response && error.response.data && error.response.data.detail
          ? error.response.data.detail
          : "ไม่สามารถติดต่อ server ได้",
      });
    }

  };
  const handleCloseDialog = () => {
    setIsOpen(false);

  };

  const updateBooking = async (e) => {

    e.preventDefault();
    if (values.customer_name && values.count_cus > 0) {
      setIsOpen(false);
      try {
        const data = {
          "reserve_detail_id": values.id,
          "reserve_detail_customer_name": values.customer_name,
          "reserve_detail_customer_tel": values.customer_tel,
          "reserve_detail_footsetid": values.setfoot == null ? '' : values.setfoot,
          "reserve_detail_footcnt": values.setfoot == null ? '' : values.count_foot,
          "reserve_detail_note": values.note,
        };

        console.log(data)
        Swal.fire({
          title: 'Loading...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
            Swal.showLoading()
          }
        });
        const token = localStorage.getItem('SavedToken');

        await axios
          .put(process.env.REACT_APP_BASE_API_URL + "/api/booking", data, {
            headers: {
              Authorization: `Bearer ${token}`
            }

          })
          .then((response) => {
            console.log("response booking", response);
            if (response.data.message === "Success") {
              Swal.fire({
                icon: "success",
                title: "Update success",
                text: response.data.message,
              });
              const token = localStorage.getItem("SavedToken");
             
              axios
                .get(process.env.REACT_APP_BASE_API_URL + "/api/booking/", {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
                .then((res) => {
                  setReserves(res.data);
                  setLoading(false);
                });
            } else {
              Swal.fire({
                icon: "error",
                title: "",
                text: response.data.detail
              });
            }
          });
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response && error.response.data && error.response.data.detail
            ? error.response.data.detail
            : "ไม่สามารถติดต่อ server ได้",
        });
      }
      //window.location.href = 'http://localhost:3000/dashboard'
      //navigate("/dashboard");

    } else {

      if (!values.customer_name) {
        Swal.fire({
          icon: "error",
          title: "Missing Information",
          text: "กรุณาใส่ข้อมูลลูกค้า",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Missing Information",
          text: "กรุณาระบุจำนวนลูกค้า",
        });
      }

    }

  };

  return (
    <>
      <HeaderContainer>
        <Typography variant="h5" fontWeight="700">
          รายการจองคิว
        </Typography>

      </HeaderContainer>

      <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={handleCloseDialog}>
        <DialogTitle sx={{ fontSize: 24 }} fontWeight="700">ข้อมูลการจอง</DialogTitle>
        <DialogContent>



          <Typography variant="h6" fontWeight="700" sx={{ marginBottom: 1 }}>ข้อมูลลูกค้า</Typography>

          <Row>
            <Col xs={12} md={6}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <TextField
                  id="customer_name"
                  label="ชื่อลูกค้า"
                  value={values.customer_name}  // Use 'value' instead of 'defaultValue'
                  onChange={handleChangeData("customer_name")}
                  fullWidth
                  required
                />
              </FormControl>
            </Col>
            <Col xs={12} md={6}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <TextField
                  id="customer_tel"
                  label="เบอร์โทร"
                  value={values.customer_tel}
                  onChange={handleChangeData("customer_tel")}
                  fullWidth
                  required
                />
              </FormControl>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <TextField
                  id="count_cus"
                  label="จำนวนคน"
                  variant="standard"
                  type="number"
                  value={values.count_cus}
                  onChange={handleChangeData("count_cus")}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Col>
            <Col xs={12} md={6}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <TextField
                  id="deposit"
                  label="จำนวนเงินมัดจำ"
                  variant="standard"
                  type="number"
                  defaultValue="0"
                  value={values.count_cus > 0 ? values.deposit : 0}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
              </FormControl>
            </Col>
            <Col>
              <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
                <InputLabel id="setfoot">เซ็ตอาหาร</InputLabel>
                <Select
                  labelId="setfoot"
                  id="setfoot"
                  value={values.setfoot}
                  onChange={handleChangeData("setfoot")}
                  label="เซ็ตอาหาร"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"FOOT000001"}>Set A</MenuItem>

                </Select>
              </FormControl>
            </Col>
            <Col>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <TextField
                  id="count_foot"
                  label="จำนวนอาหาร"
                  variant="standard"
                  type="number"
                  value={values.count_foot}
                  onChange={handleChangeData("count_foot")}
                  fullWidth
                  disabled={!values.setfoot}
                />
              </FormControl>
            </Col>

            <Col>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <TextField
                  id="note"
                  label="หมายเหตุ"
                  variant="standard"
                  value={values.note}
                  onChange={handleChangeData("note")}
                  fullWidth
                />
              </FormControl>
            </Col>

          </Row>


        </DialogContent>
        <DialogActions>

          <Button style={{ fontSize: 20 }} onClick={updateBooking} color="primary">ยืนยันการแก้ไข</Button>

          <Button style={{ fontSize: 20 }} onClick={handleCloseDialog} color="primary">Close</Button>
        </DialogActions>
      </Dialog>

      <Paper sx={{ padding: "1rem", marginTop: "-1.5rem" }}>
        <Typography fontWeight="700" variant="h6">ค้นหาระบุ</Typography>

        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs={4} md={2}>
              <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                <TextField
                  id="searchReserveid"
                  label="เลขที่การจอง"
                  variant="outlined"
                  onChange={handleChangeData("searchReserveid")}
                  fullWidth
                />
              </FormControl>
            </Col>
            <Col xs={4} md={2}>
              <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                <TextField
                  id="searchname"
                  label="ชื่อลูกค้า"
                  variant="outlined"
                  onChange={handleChangeData("searchname")}

                  fullWidth
                />
              </FormControl>
            </Col>
            <Col xs={4} md={2}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="วันที่จอง"
                    value={values.date} // access the date value from the state
                    onChange={handleChangeData('date')} // update the date value in the state
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Col>

            
            <Col xs={4} md={4}></Col>
            <Col xs={4} md={2}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <Button variant="contained" size="large" onClick={handleSubmit}>
                  ค้นหา
                </Button>
              </FormControl>
            </Col>

          </Row>


        </form>
      </Paper>
      <br></br>
      <Typography display="inline" variant="body2" style={{ color: "#5872E9" }} > <CheckCircleIcon style={{ color: "#36AE7C" }} /> รายการจองคิวสำเร็จ {rowsCount} รายการ  </Typography>
      <Typography display="inline" variant="body2" style={{ color: "#AAAAAA" }} > | </Typography>
      <Typography display="inline" variant="body2" style={{ color: "#5872E9" }} > <CancelIcon style={{ color: "#EB5353" }} /> รายการจองคิวไม่สำเร็จ 0 รายการ  </Typography>
      <br></br><br></br>

      {loading ? (
        <p>Loading...</p>
      ) : (

        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: '#F5F5F5' }}>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", fontSize: 18 }} >เลขที่การจอง</TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 18 }}>ชื่อลูกค้า</TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 18 }}>เบอร์โทร</TableCell>

                <TableCell sx={{ fontWeight: "bold", fontSize: 18 }}>จำนวนคน</TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 18 }}>วันที่จอง</TableCell>
                {/* <TableCell sx={{ fontWeight: "bold", fontSize: 18 }}>วันที่บันทึการจอง</TableCell> */}

                <TableCell sx={{ fontWeight: "bold", fontSize: 18 }}>ผลการจอง</TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 18 }}>หมายเหตุ</TableCell>

                <TableCell align="center" sx={{ fontWeight: "bold", fontSize: 18 }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reserves.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <TableRow key={row.reserve_id}>
                  <TableCell sx={{ fontSize: 16 }} component="th" scope="row">{row.reserve_id}</TableCell>
                  <TableCell sx={{ fontSize: 16 }}>{row.reserve_customer_name}</TableCell>
                  <TableCell sx={{ fontSize: 16 }}>{row.reserve_customer_tel}</TableCell>

                  <TableCell sx={{ fontSize: 16 }}>{row.reserve_count} คน</TableCell>
                  <TableCell sx={{ fontSize: 16 }}>{formatDate(row.reserve_date)}</TableCell>
                  {/* <TableCell sx={{ fontSize: 16 }}>{row.reserve_dateinsert.replace("T", " ").replace("Z", "").replace(".000", "")}</TableCell> */}

                  <TableCell sx={{ fontSize: 16 }}>{row.status_name}</TableCell>
                  <TableCell sx={{ fontSize: 16 }}>{row.reserve_note ? row.reserve_note : "-"}</TableCell>

                  <TableCell sx={{ fontSize: 16 }}>
                    <Button variant="contained" style={{ backgroundColor: "#36AE7C", color: "#fff", marginTop: "0.2rem", marginBottom: "0.2rem", marginRight: "1rem" }}
                      onClick={() => handleCopy(`/uploadslip/${formatDate(row.reserve_date)}/${row.reserve_refcode}`)}
                    >
                      <CopyIcon color="#fff" /> คัดลอก url
                    </Button>

                    <Button variant="contained" style={{ backgroundColor: "#FFCB42", marginRight: "1rem", marginTop: "0.2rem", marginBottom: "0.2rem", color: "#000000" }}
                      onClick={() => {

                        navigate(`/uploadslip/${formatDate(row.reserve_date)}/${row.reserve_refcode}`);
                      }}>
                      <LoupeIcon color={"#000"} /> รายละเอียด
                    </Button>

                    <Button variant="contained" style={{ backgroundColor: "#FFCB42", color: "#000", marginTop: "0.2rem", marginBottom: "0.2rem", marginRight: "1rem" }}
                      onClick={() => handleOpenDialog(row.reserve_id)}
                    >
                      <BorderColorIcon color={"#000"} /> แก้ไขข้อมูล
                    </Button>

                    <Button variant="contained" style={{ color: row.status_name == 'ยืนยันการจอง' ? "#fff" : "#000", marginTop: "0.2rem", marginBottom: "0.2rem", marginRight: "1rem" }}

                      onClick={() => handleChange(`/bookingchange/${formatDate(new Date())}/${row.reserve_id}`)}


                      disabled={row.status_name.trim() !== 'ยืนยันการจอง'}
                    >
                      <ChangeCircleIcon color={"#fff"} /> ย้ายการจอง
                    </Button>

                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <br></br>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={rowsCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}

    </>
  );
};

export default ManageReserve;
